import React, { useEffect, useState } from "react";
import { Container, Grid, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/Logo.png";
import Loader from "../../images/loader/Render.gif";
import { useQuery } from "react-query";

const Customer = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  useEffect(() => {
    fetch(`https://gallpax.flyjatt.com/v1/Customer/index.php?all`)
      .then((res) => res.json())
      .then((data) => {
        setCustomer(data?.data);
        setIsLoading(false);
      });
  }, []);

  return (
    <Box style={{ backgroundColor: "var(--secondary-color)", height: "100vh" }}>
      <Container>
        {/*----------- header -------------*/}
        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "200px",
                  }}
                >
                  User Management
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Typography
          variant="span"
          sx={{
            fontWeight: 500,
            fontSize: "20px",
            margin: "30px 0px",
            color: "var(--fontcolor)",
            fontFamily: "poppins",
          }}
        >
          Customer
        </Typography>

        <Box className="searchList1" my={2}>
          <input
            type="text"
            placeholder="search"
            // onChange={handelSearchItems}
          />
        </Box>

        {!isLoading ? (
          <Box className="balance-transaction" marginTop={"20px"}>
            <table>
              <tr>
                <th>Id</th>
                <th>Customer&nbsp;Id</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Password</th>
              </tr>
              {customer?.map((data) => (
                <tr>
                  <td>{data?.id}</td>
                  <td>{data?.customerId}</td>
                  <td>{data?.email}</td>
                  <td>{data?.phone}</td>
                  <td>{data?.address}</td>
                  <td>{data?.password}</td>
                </tr>
              ))}
            </table>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              width: "70vw",
              marginInline: "auto",
            }}
          >
            <Box
              style={{
                width: "50%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Loader}
                alt="loader"
                style={{
                  width: "40%",
                  objectFit: "center",
                }}
              />
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Customer;
