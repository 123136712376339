import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";

const MyComponent = () => {
  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://gallpax.flyjatt.com/v1/Duffel/AirPorts.php?name=${query}`
        );
        const json = await response.json();
        setData(json);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length >= 3) {
      fetchData();
    } else {
      setData([]);
    }
  }, [query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };
  console.log(query)

  return (
    <Box bgcolor="red">
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Type at least 3 characters"
      />

      {data.length > 0 && (
        <div>
          <h1>Data fetched from API:</h1>
          <ul style={{ color: "white" }}>
            {data.map((item) => (
              <li style={{ color: "white" }} key={item.id}>
                {item.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </Box>
  );
};

export default MyComponent;
