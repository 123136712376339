import { Box } from "@mui/material";
import React from "react";

import "./Dashboard.css";
import LiveDashboard from "./LiveDashboard/LiveDashboard";

const Dashboard = () => {
  return (
    <Box style={{ backgroundColor: "var(--secondary-color)", height: "100vh" }}>
      {/* <Box sx={{ marginTop: "50px" }}>
        <HomeSearchBox />
      </Box>
      <RecentSearch /> */}
      <LiveDashboard />
    </Box>
  );
};

export default Dashboard;
