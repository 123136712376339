import {
  Box,
  ClickAwayListener,
  Container,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Grid, Button } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Swal from "sweetalert2";
import Loader from "../../images/loader/Render.gif";
import searchModal from "../../images/searchmodal.jpg";
import SettingsIcon from "@mui/icons-material/Settings";

import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import Commission from "../../components/Commission";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import RoundSingleFlight from "../../components/SingleFlight/RoundSingleFlight";
import RoundFilterDrawer from "../../components/RoundFilterDrawer";
import RoundWayFilter from "../../components/RoundWayFilter";
import styled from "@emotion/styled";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import RoundPreloader from "../../components/Preloader/RoundPreloader";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { RoundWayFakeData } from "../../components/RoundWayFakeData";
import OneWayFilter from "../../components/OneWayFilter";
import Header from "../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import SearchModal from "./SearchModal";
import SingleFlight from "../../components/SingleFlight/SingleFlight";
import AirlineSlider from "../../components/SingleFlight/AirlineSlider";

const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
  height: "fit-content",
  padding: "20px",
  background: "#40414f",
  borderRadius: "10px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  border: "none",
  outlne: "none",
  padding: 0,
  margin: 0,
  pb: 2,
  background: "#fff",
  borderRadius: "10px",
  overflow: "hidden",
};

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#dc143c",
    maxWidth: 220,
    fontSize: "20px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const RoundSearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");

  const {
    fromSendData,
    toSendData,
    departureDate,
    returningDate,
    adultCount,
    childCount,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    className,
  } = requiredSearchData;

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });

  //fetch data form localStorage
  const commissionData = secureLocalStorage.getItem("commissionData");
  //all states that i have to send to modify search

  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [roundWayFromSearchText, setRoundWayFromSearchText] =
    useState(fromSearchText);
  const [roundWayToSearchText, setRoundWayToSearchText] =
    useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const returnNow = useRef(new Date(returningDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(returnNow.current);
  const [fromSearchDate, setFromSearchDate] = useState(departureDate);
  const [toSearchDate, setToSearchDate] = useState(returningDate);

  const [roundWayFaddress, setRoundWayFaddress] = useState(faddress);
  const [roundWayToAddress, setRoundWayToAddress] = useState(toAddress);
  const [roundWayFromSendData, setRoundWayFromSendData] =
    useState(fromSendData);
  const [roundWayToSendData, setRoundWayToSendData] = useState(toSendData);
  const [roundWayAdultCount, setRoundWayAdultCount] = useState(adultCount);
  const [roundWayChildCount, setRoundWayChildCount] = useState(childCount);
  const [roundWayInfant, setRoundWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [roundWayClassName, setRoundWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  //end

  //CM Box States
  const [customerFare, setCustomerFare] = useState(true);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  // const [openCm, setOpenCm] = useState(false);
  // const [defaultCommissionRate, setDefaultCommissionRate] = useState(true);
  // const [defaultCommissionRateAmount, setDefaultCommissionRateAmount] =
  //   useState(0);
  // const [isChange, setIsChange] = useState(false);
  //todo:end

  //todo:all flight and nxt and previous day data states
  const handleClose = () => setIsLoaded(false);

  const [isLoaded, setIsLoaded] = useState(true);
  // const [datas, setDatas] = useState(RoundWayFakeData);
  const [datas, setDatas] = useState([]);
  const [data2, setData2] = useState([]);
  // const [data2, setData2] = useState(RoundWayFakeData);
  const [noData, setNoData] = useState("No Data");
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  // todo:Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;
  // todo: previous day and next day date variables
  let tomorrowDepartureDate = new Date(fromSearchDate);
  let tomorrowReturnDate = new Date(toSearchDate);
  tomorrowDepartureDate.setDate(tomorrowDepartureDate.getDate() + 1);
  tomorrowReturnDate.setDate(tomorrowReturnDate.getDate() + 1);
  let yesterdayDepartureDate = new Date(fromSearchDate);
  let yesterdayReturnDate = new Date(toSearchDate);
  yesterdayDepartureDate.setDate(yesterdayDepartureDate.getDate() - 1);
  yesterdayReturnDate.setDate(yesterdayReturnDate.getDate() - 1);
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(datas?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let body = {
    Segment: [
      {
        DepFrom: `${fromSendData?.replace(/\s+/g, "")}`,
        ArrTo: toSendData,
        DepDate: `${new Date(departureDate).toLocaleDateString("sv")}`,
      },
      {
        DepFrom: toSendData,
        ArrTo: `${fromSendData?.replace(/\s+/g, "")}`,
        DepDate: `${new Date(returningDate).toLocaleDateString("sv")}`,
      },
    ],
    tripType: tripType,
    Adult: adultCount,
    Child: childCount,
    Infant: infant,
    Class: "economy",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          // "https://gallpax.flyjatt.com/v1/Duffel/AirSearchv1.php",
          "https://gallpax.flyjatt.com/v1/Duffel/AirSearch.php",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (data.length !== 0) {
          setIsLoaded(true);
          const uniqueData = data;
          const count = uniqueData.length;
          const pageNumber = Math.ceil(count / size);
          setPageCount(pageNumber);
          setDatas(uniqueData);
          setData2(uniqueData);
          handleClose();
        } else {
          handleClose();

          Swal.fire({
            imageUrl: Search,
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "No Flights Found",
            confirmButtonText: "Search Again...",
            confirmButtonColor: "var(--primary-color)",
          }).then(function () {
            navigate("/");
          });
        }
      } catch (error) {
        handleClose();

        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/");
        });
      }
    };
    fetchData();
  }, [
    changeState,
    size,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    navigate,
  ]);

  if (isLoaded) {
    return (
      <Box>
        <Box
          sx={{
            ...style,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <SearchModal />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "var(--third-color)" }}>
      <LandingHeader />{" "}
      <Container>
        <Box mt={4}>
          <Box mb={2}>
            <AirlineSlider
              data={datas}
              setData={setDatas}
              filteredData={data2}
              setfilteredData={setData2}
            />
          </Box>
          <Grid container columnSpacing={2}>
            {/* //todo: filter*/}
            <Grid
              item
              sx={{
                p: 0,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
              lg={2.7}
              width="100%"
            >
              <OneWayFilter
                data={datas}
                setData={setDatas}
                filteredData={data2}
                setfilteredData={setData2}
                noData={noData}
                setNoData={setNoData}
                departureDate={departureDate}
                setFrom={setFrom}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={9.3}>
              {/* //todo: Flight Search Result section */}
              <Box
                sx={{
                  bgcolor: "var(--white)",
                  p: { xs: 1, sm: 1, md: 2 },
                  borderRadius: "10px",
                }}
              >
                <Grid container columnSpacing={3} alignItems="center">
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                      },
                    }}
                  >
                    Cheapest
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                      },
                    }}
                  >
                    Quickest
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                      },
                    }}
                  >
                    Lowest&nbsp;Emissions
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{ cursor: "pointer" }}
                    onClick={() => modifyHandleOpen()}
                  >
                    Modify&nbsp;Search
                  </Grid>
                </Grid>
              </Box>

              <Box textAlign="center" my={2}>
                Showing <strong>{datas?.length}</strong> Flight
              </Box>
              <Grid container>
                {/* //todo: AirLine Slider */}

                {/* //todo:Search Result Section */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {data2?.slice(0, size)?.map((roundTrip, index) => {
                    return (
                      // <RoundSingleFlight
                      //   key={index}
                      //   flightData={roundTrip}
                      //   adultCount={adultCount}
                      //   childCount={childCount}
                      //   infant={infant}
                      //   from={fromSendData}
                      //   to={toSendData}
                      //   fromAddress={faddress}
                      //   toAddress={toAddress}
                      //   tripType={tripType}
                      //   departureDate={departureDate}
                      //   returingDate={returningDate}
                      //   agentFarePrice={agentFarePrice}
                      //   setAgentFarePrice={setAgentFarePrice}
                      //   commisionFarePrice={commisionFarePrice}
                      //   setCommisionFarePrice={setCommisionFarePrice}
                      //   customerFare={customerFare}
                      //   setCustomerFare={setCustomerFare}
                      // />
                      <SingleFlight
                        key={index}
                        flightData={roundTrip}
                        tripType={tripType}
                        adultCount={adultCount}
                        childCount={childCount}
                        infant={infant}
                        from={fromSendData}
                        to={toSendData}
                        fromAddress={faddress}
                        toAddress={toAddress}
                        agentFarePrice={agentFarePrice}
                        setAgentFarePrice={setAgentFarePrice}
                        commisionFarePrice={commisionFarePrice}
                        setCommisionFarePrice={setCommisionFarePrice}
                        customerFare={customerFare}
                        setCustomerFare={setCustomerFare}
                      />
                    );
                  })}
                </Grid>
                {/* //todo: Pagination section */}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pageCount}
                        onChange={handlePageChange}
                        shape="rounded"
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Modal open={modifyOpen} onClose={modifyHandleClose}>
          <Container>
            <Box sx={modalStyle}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                type={type}
                setType={setType}
                value={value}
                setValue={setValue}
                fromSearchText={roundWayFromSearchText}
                setFromSearchText={setRoundWayFromSearchText}
                toSearchText={roundWayToSearchText}
                setToSearchText={setRoundWayToSearchText}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={roundWayFaddress}
                setfaddress={setRoundWayFaddress}
                toAddress={roundWayToAddress}
                setToAddress={setRoundWayToAddress}
                fromSendData={roundWayFromSendData}
                setFromSendData={setRoundWayFromSendData}
                toSendData={roundWayToSendData}
                setToSendData={setRoundWayToSendData}
                adultCount={roundWayAdultCount}
                setAdultCount={setRoundWayAdultCount}
                childCount={roundWayChildCount}
                setChildCount={setRoundWayChildCount}
                infant={roundWayInfant}
                setInfant={setRoundWayInfant}
                result={result}
                setResult={setResult}
                className={roundWayClassName}
                setClassName={setRoundWayClassName}
                changeState={changeState}
                setChangeState={setChangeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
              />
            </Box>
          </Container>
        </Modal>
      </Container>
      <Footer />
    </Box>
  );
};

export default RoundSearchResult;
