import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./HomeSearchBox.css";

import { NavLink, Outlet } from "react-router-dom";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import MenuBookTwoToneIcon from "@mui/icons-material/MenuBookTwoTone";

import FlightSearchBox from "./../FlightSearchBox/FlightSearchBox";

const HomeSearchBox = ({ color, backgroundColor }) => {
  const navigate = useNavigate();
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState("return");
  const [fromSearchText, setFromSearchText] = useState(
    "Dublin Airport (DUB)"
  );
  const [toSearchText, setToSearchText] = useState("New York (NYC)");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity

  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 1));
  const [to, setTo] = useState(addDays(now.current, 3));
  const [faddress, setfaddress] = useState("Dublin, IE");
  const [toAddress, setToAddress] = useState("New York, US");
  const [fromSendData, setFromSendData] = useState("DUB");
  const [toSendData, setToSendData] = useState("NYC");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("Economy");

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  // For Oneway
  // For return

  return (
    <Box>
      <Container>
        <Box
          // className="home-search-box-2"
          sx={{
            px: { xs: 0, sm: 0, md: 0 },
            borderRadius: "5px",
          }}
        >
          <TabContext value={type}>
            <Box
              sx={{
                bgcolor: "transparent",

                width: {
                  lg: "fit-content",
                  md: "fit-content",
                  sm: "100%",
                  xs: "100%",
                },
                marginLeft: { Xs: 0, sm: 0, md: "17%" },
                ".MuiTabs-root button": {
                  backgroundColor: "var(--secondary-color)",
                  color: "#fff",
                  opacity: 1,
                  borderRadius: "5px 5px 0px 0",
                },
                ".MuiTabs-root button:last-child": {
                  borderRadius: "5px 5px 0px 0",
                },
                "button.Mui-selected": {
                  fontWeight: 500,
                  borderBottom: "none !important",
                  backgroundColor: "#fff",
                  color: "var(--secondary-color)",
                },
                ".MuiTabs-indicator": {
                  display: "none !important",
                },
              }}
              // display="flex"
              // justifyContent="center"
            >
              <TabList
                value={type}
                onChange={handleTypeChange}
                aria-label="Dashboard Tabs"
                variant="scrollable"
              >
                <Tab
                  label="Flight"
                  value="flight"
                  sx={{
                    p: { xs: "5px 10px", md: "5px 50px" },
                    marginRight: { Xs: 0, sm: 0, md: 2 },
                    fontSize: { xs: "11px", sm: "15px" },
                  }}
                />

                <Tab
                  label="Hotel"
                  value="hotel"
                  sx={{
                    p: { xs: "5px 10px", md: "5px 50px" },
                    marginRight: { Xs: 0, sm: 0, md: 2 },

                    fontSize: { xs: "11px", sm: "15px" },
                  }}
                />
              </TabList>
            </Box>
            <Box>
              <TabPanel value="flight">
                <FlightSearchBox
                  type={type}
                  setType={setType}
                  value={value}
                  setValue={setValue}
                  fromSearchText={fromSearchText}
                  setFromSearchText={setFromSearchText}
                  toSearchText={toSearchText}
                  setToSearchText={setToSearchText}
                  departureDate={departureDate}
                  setDepartureDate={setDepartureDate}
                  returningDate={returningDate}
                  setReturningDate={setReturningDate}
                  travelDate={travelDate}
                  setTravelDate={setTravelDate}
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  faddress={faddress}
                  setfaddress={setfaddress}
                  toAddress={toAddress}
                  setToAddress={setToAddress}
                  fromSendData={fromSendData}
                  setFromSendData={setFromSendData}
                  toSendData={toSendData}
                  setToSendData={setToSendData}
                  adultCount={adultCount}
                  setAdultCount={setAdultCount}
                  childCount={childCount}
                  setChildCount={setChildCount}
                  infant={infant}
                  setInfant={setInfant}
                  result={result}
                  setResult={setResult}
                  className={className}
                  setClassName={setClassName}
                  setChangeState={setChangeState}
                  changeState={changeState}
                  changeFrom={changeFrom}
                  setChangeFrom={setChangeFrom}
                  // searchData={searchData}
                  // setSearchData={setSearchData}
                />
              </TabPanel>
              <TabPanel value="hotel">
                <Box>Comming soon Hotel</Box>
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Container>
    </Box>
  );
};

export default HomeSearchBox;
