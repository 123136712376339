import React, { useEffect, useState } from "react";
import {
  Pagination,
  Stack,
  Tooltip,
  CircularProgress,
  Modal,
} from "@mui/material";
import { Container, Grid, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/Logo.png";
import Loader from "../../images/loader/Render.gif";
import { useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import commaNumber from "comma-number";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  boxShadow: 24,
  p: 2,
};

const Customer = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [mainPaymentData, setmainPaymentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);

  const [page, setPage] = useState(1);

  let size = 20;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setmainPaymentData(customer?.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch(`https://gallpax.flyjatt.com/v1/Deposit/index.php?all`)
      .then((res) => res.json())
      .then((data) => {
        const count = data?.data.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setCustomer(data?.data);
        setmainPaymentData(data?.data);
        setIsLoading(false);
      });
  }, []);

  //  search functionality handle

  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = customer.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setmainPaymentData(filterData);
    } else if (searchInput === "") {
      setmainPaymentData(mainPaymentData);
    }
  };

  // modal
  const [open, setOpen] = React.useState(false);

  const [id, setId] = useState("");
  const [reason, setReason] = useState("");

  const handleClickOpen = (customerId) => {
    setOpen(true);
    setId(customerId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // payment approved function
  const handleApprove = (depositId, customerId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You wants to approve this deposit?",
      showCancelButton: true,
      confirmButtonColor: "green",
      confirmButtonText: "Yes Approve it!",
      cancelButtonColor: "red",
      cancelButtonText: "Don't Approve it",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let url = `https://gallpax.flyjatt.com/v1/Deposit/index.php?approved`;
        let body = JSON.stringify({
          depositId: depositId,
          customerId: customerId,
        });
        fetch(url, {
          method: "POST",
          headers: {
            Accept: "*/*",
            "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          },
          body: body,
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.status === "success") {
              Swal.fire({
                icon: "success",
                title: "success",
                text: "Payment Approved Successfully",
                confirmButtonText: "ok",
              }).then(() => {
                navigate(0);
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Failed",
                text: "Payment Failed",
                confirmButtonText: "ok",
              });
            }
          })
          .catch((err) => {
            console.log(err);
            Swal.fire({
              icon: "error",
              title: `${err?.message}`,
              text: "Payment Failed",
              confirmButtonText: "ok",
            });
          });
      }
    });
  };
  // payment reject function
  const handleReject = (e) => {
    e.preventDefault();
    let url = `https://gallpax.flyjatt.com/v1/Deposit/index.php?rejected`;

    let body = JSON.stringify({
      customerId: id,
      actionBy: "admin",
      reason: reason,
    });

    console.log("reject", body);

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        handleClose();

        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Payment Reject Successfully",
            confirmButtonText: "ok",
          }).then(() => {
            navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Failed",
            text: "Payment Failed",
            confirmButtonText: "ok",
          });
        }
      })
      .catch((err) => {
        handleClose();
        Swal.fire({
          icon: "error",
          title: `${err?.message}`,
          text: "Payment Failed",
          confirmButtonText: "ok",
        });
      });
  };

  return (
    <Box
      style={{
        backgroundColor: "var(--secondary-color)",
        height: "100vh",
      }}
    >
      <Container>
        {/*----------- header -------------*/}
        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "200px",
                  }}
                >
                  User Management
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Typography
          variant="span"
          sx={{
            fontWeight: 500,
            fontSize: "20px",
            margin: "30px 0px",
            color: "var(--fontcolor)",
            fontFamily: "poppins",
          }}
        >
          Payment Amount
        </Typography>

        <Box className="searchList1" my={2}>
          <input
            type="text"
            placeholder="search"
            onChange={handelSearchItems}
          />
        </Box>

        {!isLoading ? (
          <Box className="balance-transaction" marginTop={"20px"}>
            <table>
              <thead>
                <tr>
                  <th> Customer&nbsp;Id </th>
                  <th> Sender </th>
                  <th> Receiver </th>
                  <th> &nbsp;Method&nbsp; </th>
                  <th> &nbsp;TransactionId&nbsp; </th>
                  <th>&nbsp;Amount&nbsp;</th>
                  <th> Date </th>
                  <th> Status </th>
                  <th> Remarks </th>
                  <th>Operation</th>
                  <th> Vew </th>
                </tr>
              </thead>
              {mainPaymentData?.length < 0 ? (
                ""
              ) : (
                <tbody>
                  {mainPaymentData?.slice(0, size).map((data) => (
                    <tr>
                      <td>{data?.customerId}</td>
                      <td>{data?.sender}</td>
                      <td>{data?.receiver}</td>
                      <td>{data?.paymentmethod}</td>
                      <td>{data?.transactionId}</td>
                      <td>{data?.amount}</td>
                      <td>{data?.created_at}</td>
                      <td>{data?.status}</td>
                      <td>{data?.remarks}</td>
                      <td>
                        {data?.status === "approved" ? (
                          "Complete"
                        ) : data?.status === "rejected" ? (
                          "Complete"
                        ) : (
                          <>
                            <CheckCircleIcon
                              sx={{
                                cursor: "pointer",
                                color: "green",
                                bgcolor: "#fff",
                                borderRadius: "100%",
                              }}
                              onClick={() =>
                                handleApprove(data?.depositId, data?.customerId)
                              }
                            />
                            &nbsp;&nbsp;
                            <CancelIcon
                              sx={{
                                cursor: "pointer",
                                color: "red",
                                bgcolor: "#fff",
                                borderRadius: "100%",
                              }}
                              onClick={() => handleClickOpen(data?.customerId)}
                            />
                          </>
                        )}
                      </td>

                      <td>
                        <a
                          id="anchor"
                          href={`https://gallpax.flyjatt.com/assets/deposit/${data?.attachment}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
            {mainPaymentData?.length < 0 ? (
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: 22,
                  textAlign: "center",
                  border: "2px solid #fff",
                  width: "100%",
                }}
              >
                No Data
              </Typography>
            ) : (
              ""
            )}
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              width: "70vw",
              marginInline: "auto",
            }}
          >
            <Box
              style={{
                width: "50%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={Loader}
                alt="loader"
                style={{
                  width: "40%",
                  objectFit: "center",
                }}
              />
            </Box>
          </Box>
        )}
      </Container>

      {/* Rejection model star here */}

      <div>
        <form>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Rejection Reason"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <Box width="250px">
                  <form onSubmit={handleReject}>
                    <input
                      required
                      style={{
                        width: "100%",
                        outline: "none",
                        padding: "6px 5px",
                        border: "1px solid var(--flyhub)",
                        borderRadius: "3px",
                      }}
                      onChange={(e) => setReason(e.target.value)}
                    />
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      mt={2}
                    >
                      <Button
                        size="small"
                        sx={{
                          bgcolor: "red",
                          color: "#fff",
                          "&:hover": {
                            bgcolor: "red",
                          },
                        }}
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        size="small"
                        sx={{
                          bgcolor: "green",
                          color: "#fff",
                          "&:hover": {
                            bgcolor: "green",
                          },
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Stack>
                  </form>
                </Box>
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
              <Button onClick={handleClose}>Close</Button>
              <Button onClick={handleReject}>Save</Button>
            </DialogActions> */}
          </Dialog>
        </form>
      </div>

      {/* Rejection model end here */}

      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            sx={{
              width: "100%",
              my: 3,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              borderTop: "1px solid var(--primary-color)",
              marginTop: "30px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                my: 3,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",

                marginTop: "8px",
              }}
            >
              <Typography style={{ fontSize: "15px", color: "#fff" }}>
                Showing Results {page} - {pageCount}
              </Typography>
              <ThemeProvider theme={theme}>
                <Stack spacing={2}>
                  <Pagination
                    count={pageCount}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Stack>
              </ThemeProvider>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customer;
