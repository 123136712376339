import {
  Box,
  IconButton,
  MenuItem,
  Typography,
  Menu,
  ClickAwayListener,
  Link,
  Grid,
  Stack,
} from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Logo from "../../images/Logo.png";
import Avatar from "@mui/material/Avatar";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import "./LandingHeader.css";
import secureLocalStorage from "react-secure-storage";
import useAuthentication from "../../hooks/useAuthentication";

export const LandingHeader = () => {
  const user = secureLocalStorage.getItem("user-info");
  const { loginUser } = useAuthentication();
  const { logout } = useAuthentication();
  const location = useLocation();
  const navigate = useNavigate();
  // todo: for mobile device

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const styles = {
    position: "absolute",
    top: 28,
    zIndex: 1,
    p: 1,
    width: "200px",
    bgcolor: "background.paper",
  };

  return (
    <Box>
      <Box sx={{ py: 1, textAlign: "right" }}>
        <Box sx={{ maxWidth: "1300px", margin: "auto" }}>
          <Grid
            container
            spaceing={{ xs: 1, sm: 2 }}
            justifyContent="space-between"
          >
            <Grid
              item
              xs={2.5}
              sm={3}
              md={4}
              lg={7}
              margin={{ xs: "auto", sm: "0" }}
            >
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                  width: { xs: "70px", sm: "80px", md: "80px" },
                  height: { xs: "70px", sm: "80px", md: "80px" },
                  marginLeft: { xs: 1, md: "20%" },
                }}
                onClick={() => navigate("/")}
              >
                <img
                  src={Logo}
                  alt="Travel Sense"
                  style={{ width: "100%", height: "100%", borderRadius: "50%" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={9.5}
              sm={9}
              md={8}
              lg={5}
              margin={{
                xs: "auto",
                sm: "0",
              }}
              sx={{
                a: {
                  fontSize: { xs: 12, sm: 12, md: 16 },
                  fontWeight: 500,
                },
              }}
            >
              <Stack
                direction="row"
                spacing={{ xs: 1, sm: 2, md: 4 }}
                justifyContent="space-between"
                alignItems="center"
                px={{ xs: 1, sm: 2 }}
                bgcolor="#fff"
                py={2}
                // lineHeight={{ xs: "40px", sm: "60px", md: "60px" }}
              >
                <NavLink
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--secondary-color)",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    color="var(--secondary-color)"
                    fontWeight="500"
                  >
                    <StarBorderOutlinedIcon
                      sx={{
                        color: "green",
                        fontSize: { xs: 16, sm: 18, md: 20 },
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                    Trustpilot
                  </Stack>
                </NavLink>

                <Box underline="none">
                  <a
                    href="https://wa.me/+971509517784"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      sx={{
                        color: "var(--secondary-color)",
                        display: { xs: "flex" },
                        alignItems: "center",
                        fontWeight: 500,
                      }}
                    >
                      <WhatsAppIcon
                        sx={{
                          fontSize: { xs: 16, sm: 18, md: 20 },
                          color: "var(--secondary-color)",
                        }}
                      />
                      &nbsp;Chat&nbsp;with&nbsp;us{" "}
                    </Box>
                  </a>
                </Box>
                <Box
                  sx={{
                    // display: { xs: "none", sm: "block" },
                    input: {
                      height: "30px",
                      width: "100%",
                      padding: "4px 10px",
                    },
                  }}
                >
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <Box sx={{ position: "relative" }}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        type="button"
                        onClick={handleClick}
                        sx={{
                          fontSize: { xs: 12, sm: 12, md: 16 },
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTh6kKOdr_gvffMFNrTKZoRgA2523MwGLj85qmx_Skj8A&s"
                          style={{ width: "20px" }}
                        />
                        &nbsp;&nbsp;EN&nbsp;(EUR)&nbsp;
                        <ExpandMoreIcon />
                      </Stack>
                      {open ? (
                        <Box sx={{ ...styles, right: { xs: "0", sm: -16 } }}>
                          <Typography
                            sx={{
                              fontSize: { xs: 12, sm: 12, md: 16 },
                              textAlign: "left",
                            }}
                          >
                            Language
                          </Typography>
                          <input value="English" />
                          <Typography
                            sx={{
                              fontSize: { xs: 12, sm: 12, md: 16 },
                              textAlign: "start",
                              mt: 0.5,
                            }}
                          >
                            Currency
                          </Typography>
                          <input value="Euro" />
                        </Box>
                      ) : null}
                    </Box>
                  </ClickAwayListener>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
