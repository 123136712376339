import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import commaNumber from "comma-number";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AirlineSlider = ({ filteredData, setfilteredData, data, setData }) => {
  const [carearFlight, setCarearFlight] = useState([]);
  const [prevName, setPrevName] = useState(null);
  useEffect(() => {
    let carearArray = data.map((name) => name.Caree);
    let carears = {};
    carearArray.forEach((i) => (carears[i] = false));
    let totalCarear = Object.keys(carears).map((item) => ({
      name: item,
      imgname: item,
    }));
    setCarearFlight(totalCarear);
  }, [data]);

  const handleflightCarear = (name) => {
    // Check if the provided name is the same as the previously filtered name.
    if (name === prevName) {
      setfilteredData(data); // If it's the same, remove the filter and show all data.
      setPrevName(null); // Reset the previously filtered name.
    } else {
      let updatedflight = data.filter((item) => item.Caree === name);
      setfilteredData(updatedflight); // Apply the filter for the new name.
      setPrevName(name); // Update the previously filtered name.
    }
  };

  let airlineNames = data.map((item) => ({
    careerName: item.Caree,
    totalFare: item?.TotalFare,
    CarrierLogo: item?.CarrierLogo,
    Currency: item?.Currency,
    isActive: false,
  }));

  let sortedAirlineNames = airlineNames.sort(
    (prev, curr) => curr?.TotalFare - prev.totalFare
  );

  let uniqueAirlineNames = [
    ...new Map(
      sortedAirlineNames.map((item) => [item.careerName, item])
    ).values(),
  ];

  let airlineDuplicateCount = uniqueAirlineNames.reduce((acc, curr) => {
    const str = JSON.stringify(curr.careerName);
    acc[str] = (acc[str] || 0) + 1;
    return acc;
  }, {});

  uniqueAirlineNames.forEach((item) => {
    for (let count in airlineDuplicateCount) {
      if (item.careerName === JSON.parse(count)) {
        item.count = airlineDuplicateCount[count];
      }
    }
  });

  var settings = {
    dots: false,
    speed: 500,
    slidesToShow: uniqueAirlineNames.length < 6 ? uniqueAirlineNames.length : 6,
    slidesToScroll:
      uniqueAirlineNames.length < 1 ? uniqueAirlineNames.length : 1,
    arrows: true,
    prevArrow: <ArrowBackIosNewIcon />,
    nextArrow: <ArrowForwardIosIcon />,
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow:
            uniqueAirlineNames.length < 3 ? uniqueAirlineNames.length : 3,
          slidesToScroll:
            uniqueAirlineNames.length < 1 ? uniqueAirlineNames.length : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow:
            uniqueAirlineNames.length < 3 ? uniqueAirlineNames.length : 3,
          slidesToScroll:
            uniqueAirlineNames.length < 1 ? uniqueAirlineNames.length : 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow:
            uniqueAirlineNames.length < 2 ? uniqueAirlineNames.length : 2,
          slidesToScroll:
            uniqueAirlineNames.length < 1 ? uniqueAirlineNames.length : 1,
        },
      },
    ],
  };

  return (
    <div
      className="airlines-slider"
      style={{
        background: "var(--card-color)",
        width: "100%",
        height: "100%",
        borderRadius: "5px",
        position: "relative",
      }}
    >
      <Box
        sx={{
          borderTop: "1px solid var(--gray)",
          position: "absolute",
          top: "50%",
          width: "100%",
        }}
      ></Box>
      <Slider {...settings}>
        {uniqueAirlineNames.map((item, index) => (
          <Button
            key={index}
            sx={{
              backgroundColor: `${
                prevName === item?.careerName ? "var(--transit)" : "transparent"
              }`,
              "&:hover": {
                backgroundColor: `${
                  prevName === item?.careerName
                    ? "var(--transit)"
                    : "transparent"
                }`,
              },
            }}
          >
            <Tooltip title={item.careerName}>
              <Stack
                direction="column"
                sx={{
                  backgroundColor: "transparent",
                  color: "var(--secondary-color)",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  ".slick-slide > div": {
                    margin: "1px 5px",
                    width: "auto",
                  },
                  ".slick-list": {
                    margin: "0px -10px",
                  },
                }}
                onClick={() => {
                  handleflightCarear(item.careerName);
                }}
              >
                <img
                  src={item.CarrierLogo} // Replace with the appropriate image URL for each airline
                  alt="flight-icon"
                  style={{
                    height: "35px",
                    padding: "2px",
                  }}
                />

                <Typography
                  textAlign="left"
                  fontSize={{ xs: 12, sm: 16, md: 25 }}
                  className="activecolor"
                  color={"var(--result)"}
                  fontWeight={{ xs: 500, sm: 500, md: 600 }}
                  pt={0.5}
                >
                  <span
                    style={{
                      fontSize: "12px",
                      fontWeight: 500,
                      verticalAlign: "text-top",
                    }}
                  >
                    {item?.Currency}
                  </span>
                  &nbsp;{commaNumber(item?.totalFare)}
                </Typography>
              </Stack>
            </Tooltip>
          </Button>
        ))}
      </Slider>
    </div>
  );
};

export default AirlineSlider;
