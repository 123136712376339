import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const MyProfile = () => {
  const user = secureLocalStorage.getItem("user-info");
  console.log("profile", user);
  const profile = user?.data[0];

  return (
    <Box
      sx={{
        mt: 4,
        width: "100%",
      }}
    >
      <Stack direction="row" spacing={4} alignItems="center">
        <Typography
          sx={{ color: "var(--primary-color)", fontSize: 23, fontWeight: 500 }}
        >
          My Profile
        </Typography>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 14,
            fontWeight: 500,
            px: 3,
            py: 1,
            cursor: "pointer",
            borderRadius: 10,
            bgcolor: "var(--profile-btn)",
          }}
        >
          <NavLink
            to="/updateprofile"
            style={{
              fontSize: "14px",
              textDecoration: "none",
              color: "var(--primary-color)",
            }}
          >
            Update Profile
          </NavLink>
        </Typography>
      </Stack>
      <Box my={5}>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            my: 3,
          }}
        >
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Name Title
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Mr / Miss
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              First Name / Given Name{" "}
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {profile?.name?.split(" ")[0]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Last Name / Surname{" "}
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {profile?.name?.split(" ")[1]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Gender{" "}
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Data
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              DOB
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Data{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Nationality
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Data
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Profile Image
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Image
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box my={5}>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            my: 3,
          }}
        >
          Contact Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Email
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {profile?.email}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Phone Number{" "}
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {profile?.phone}
            </Typography>
          </Grid>
          <Grid item md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Address
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              {profile?.address || "No Data"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box my={5}>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            my: 3,
          }}
        >
          Passport Information
        </Typography>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Number
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Data
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Expiry Date
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Data{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Copy
            </Typography>
            <Typography
              sx={{
                color: "var(--profile-txt)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              No Image
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default MyProfile;
