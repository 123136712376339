import { Box, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import commaNumber from "comma-number";
import CircularProgress from "@mui/material/CircularProgress";
import { format } from "date-fns";
import Logo from "../../images/PDFLogo.png";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import moment from "moment";

import { width } from "@mui/system";

const BookingPDF = ({ allData, flightData, passengerData, copy }) => {
  const componentRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const handleToPrint = useReactToPrint({
    content: () => componentRef.current,
    // documentTitle: `Booking_confirmation_with_price_${allData?.deptFrom}-${allData?.arriveTo}_${allData?.travelDate}_PAX-${allData?.pax}`,
    documentTitle: "booking copy",
    pageStyle: "@page { size: 210mm 297mm }",
  });

  const calDuration = (mins) => {
    let h = Math.floor(mins / 60);
    let m = mins % 60;
    h = h < 10 ? "0" + h : h; // (or alternatively) h = String(h).padStart(2, '0')
    m = m < 10 ? "0" + m : m; // (or alternatively) m = String(m).padStart(2, '0')
    return `${h}:${m}`;
  };

  return (
    <>
      <Box className="invoice-btn">
        <Typography
          onClick={handleToPrint}
          sx={{
            color: "var(--primary-color)",
            py: 1,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          {copy}&nbsp;&nbsp;
          <DownloadForOfflineIcon sx={{ color: "var(--primary-color)" }} />
        </Typography>
      </Box>
      <Box mt={5} bgcolor="#fff" display="none">
        <Box ref={componentRef}>
          <Box mt={2}>
            <Stack direction="row" justifyContent="space-between" mb={2}>
              <Box>
                <img src={Logo} alt="companyLog" width="180px" />
              </Box>
              <Typography
                sx={{ color: "#57595D", fontSize: 15, fontWeight: 500 }}
              >
                {copy}
              </Typography>
              <Box>
                <Typography
                  sx={{ color: "#000000", fontSize: 15, fontWeight: 500 }}
                >
                  Booking Reference{" "}
                </Typography>
                <Typography
                  sx={{ color: "#000000", fontSize: 15, fontWeight: 500 }}
                >
                  {allData?.PNR}
                </Typography>
              </Box>
            </Stack>
            <Box
              padding={2}
              boxShadow="0px 3px 6px 0px rgba(0,0,0,0.16),0px 3px 6px 0px rgba(0,0,0,0.23)"
            >
              {flightData?.map((data, index) => (
                <Box my={2} key={index}>
                  {/* Departur  */}

                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ color: "#000000", fontSize: 14, fontWeight: 500 }}
                    >
                      {new Date(data?.DepTime).toLocaleString("en-uk", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                    <Typography
                      sx={{ color: "#000000", fontSize: 12, fontWeight: 500 }}
                    >
                      Depart from {data?.DepAirport}
                    </Typography>
                  </Stack>

                  {/* Total Duration  */}

                  <Typography
                    sx={{
                      color: "#57595D",
                      fontSize: 12,
                      fontWeight: 400,
                      my: 1,
                    }}
                  >
                    Flight Duration{" "}
                    {moment
                      .utc(
                        moment(data?.DepTime, "YYYY-MM-DDTHH:mm:ss").diff(
                          moment(data?.ArrTime, "YYYY-MM-DDTHH:mm:ss")
                        )
                      )
                      .format("HH:mm:ss")}
                  </Typography>
                  {/* Arrival  */}

                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ color: "#000000", fontSize: 14, fontWeight: 500 }}
                    >
                      {new Date(data?.ArrTime).toLocaleString("en-uk", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                    </Typography>
                    <Typography
                      sx={{ color: "#000000", fontSize: 12, fontWeight: 500 }}
                    >
                      Arrive at {data?.ArrAirport}
                    </Typography>
                  </Stack>

                  {/* Class name number  */}
                  <Stack direction="row" justifyContent="space-between">
                    <Typography
                      sx={{ color: "#57595D", fontSize: 14, fontWeight: 500 }}
                    >
                      Class: {allData?.CabinClass}
                    </Typography>
                    <Stack direction="row" alignItems={"center"}>
                      <Box width="30px" height="30px">
                        <img
                          src={data?.MarkettingCarrierLogo}
                          alt="flightlogo"
                          width="30px"
                          height="30px"
                        />{" "}
                      </Box>
                      <Typography
                        sx={{ color: "#57595D", fontSize: 12, fontWeight: 500 }}
                      >
                        {data?.MarkettingCarrierName}
                      </Typography>
                    </Stack>
                    <Typography
                      sx={{ color: "#57595D", fontSize: 12, fontWeight: 500 }}
                    >
                      {data?.MarkettingCarrier}
                      {"-"}
                      {data?.MarkettingCarrierNumber}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Box>

            {/* Passengers  */}
            <Box
              mt={2}
              boxShadow="0px 3px 6px 0px rgba(0,0,0,0.16),0px 3px 6px 0px rgba(0,0,0,0.23)"
              padding={2}
            >
              {passengerData?.map((data) => (
                <Box my={2}>
                  <Typography
                    sx={{
                      color: "black",
                      fontSize: 18,
                      fontWeight: 500,
                      textTransform: "capitalize",
                    }}
                  >
                    {data?.type}
                  </Typography>
                  <Grid container justifyContent="space-between">
                    <Grid item xs={3}>
                      <Typography
                        sx={{ color: "#57595D", fontSize: 14, fontWeight: 500 }}
                      >
                        Name
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: 14,
                          fontWeight: 500,
                          textTransform: "capitalize",
                        }}
                      >
                        {data?.title} {data?.given_name} {data?.family_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ color: "#57595D", fontSize: 14, fontWeight: 500 }}
                      >
                        Date Of Birth
                      </Typography>
                      <Typography
                        sx={{ color: "black", fontSize: 14, fontWeight: 500 }}
                      >
                        {data?.born_on
                          ? format(new Date(data?.born_on), "dd MMM yyyy")
                          : "Date of Birth"}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        sx={{ color: "#57595D", fontSize: 14, fontWeight: 500 }}
                      >
                        Gender
                      </Typography>
                      <Typography
                        sx={{ color: "black", fontSize: 14, fontWeight: 500 }}
                      >
                        {data?.gender === "m" ? "Male" : "Female"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>

            {/* Ticket Number  */}
            {allData?.Status === "Ticketed" && (
              <Box
                mt={2}
                boxShadow="0px 3px 6px 0px rgba(0,0,0,0.16),0px 3px 6px 0px rgba(0,0,0,0.23)"
                padding={2}
              >
                <Grid container>
                  <Grid xs={4}>
                    <Box my={2}>
                      {allData?.Passengers?.map((data) => (
                        <Typography
                          sx={{
                            color: "black",
                            fontSize: 14,
                            fontWeight: 500,
                            textTransform: "capitalize",
                          }}
                        >
                          {data?.title}&nbsp;
                          {data?.given_name}&nbsp;
                          {data?.family_name}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                  <Grid xs={6}>
                    <Box my={2}>
                      {allData?.Tickets?.map((data) => (
                        <Typography
                          sx={{ color: "black", fontSize: 14, fontWeight: 500 }}
                        >
                          {data?.unique_identifier}
                        </Typography>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BookingPDF;
