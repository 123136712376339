/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import moment from "moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--transit)",
    maxWidth: 300,
    padding: "8px",
  },
}));

const RoundTransit = ({ flightData }) => {
  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}min`;
    return transit;
  };
  const depTime = flightData?.Segments[0][0]?.DepTime;
  const arrTime =
    flightData?.Segments[0][0]?.ArrTime;

  return (
    <Box textAlign={"center"} pr={1}>
      {flightData?.Segments[0].length === 3 ? (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box display="flex">
                <Box borderRight="2px solid var(--secondary-color)" px={1}>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Transit:{" "}
                    {transitCalculation(
                      flightData?.Segments[0]?.ArrTime,
                      flightData?.Segments[1]?.DepTime
                    )}{" "}
                    <br />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {flightData?.Segments[1].DepFrom}{" "}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {flightData?.Segments[1]?.DepCountry} <br />
                    {flightData?.Segments[1]?.MarkettingCarrier}{" "}
                    {flightData?.Segments[1]?.MarkettingCarrierNumber}
                    {/* {" & "}
                    {transitCalculation(
                      flightData?.Segments[1]?.DepTime,
                      flightData?.Segments[1]?.ArrTime
                    )} */}
                    <br />
                    {format(
                      new Date(flightData?.Segments[1]?.DepTime?.toString()),
                      "dd MMM yyyy hh:mm a"
                    )}
                  </Typography>
                </Box>

                <Box px={1}>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "12px",
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    Transit:{" "}
                    {transitCalculation(
                      flightData?.Segments[1]?.ArrTime,
                      flightData?.Segments[2]?.DepTime
                    )}{" "}
                    <br />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      {flightData?.Segments[2].DepFrom}{" "}
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {flightData?.Segments[2]?.DepCountry} <br />
                    {flightData?.Segments[2]?.MarkettingCarrier}{" "}
                    {flightData?.Segments[2]?.MarkettingCarrierNumber}
                    {/* {" & "}
                    {transitCalculation(
                      flightData?.Segments[2]?.DepTime,
                      flightData?.Segments[2]?.ArrTime
                    )} */}
                    <br />
                    {format(
                      new Date(flightData?.Segments[2]?.DepTime?.toString()),
                      "dd MMM yyyy hh:mm a"
                    )}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          }
          followCursor
        >
          <Box>
            <Typography
              sx={{
                color: "var(--gray)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              {transitCalculation(depTime, arrTime)}
            </Typography>
            <Box className="stop-bar-parent">
              <CircleIcon
                sx={{ color: "var(--transit)", fontSize: "14px", zIndex: 2 }}
              />
              <CircleIcon
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "12px",
                  zIndex: 2,
                }}
              />
              <CircleIcon
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "12px",
                  zIndex: 2,
                }}
              />
              <CircleIcon
                sx={{ color: "var(--transit)", fontSize: "14px", zIndex: 2 }}
              />
              <Box className="stop-bar-line"></Box>
            </Box>
            <Typography
              sx={{
                color: "var(--gray)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              2 STOP
            </Typography>
          </Box>
        </HtmlTooltip>
      ) : flightData?.Segments.length === 2 ? (
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "12px",
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Transit:{" "}
                {transitCalculation(
                  flightData?.Segments[0]?.ArrTime,
                  flightData?.Segments[1]?.DepTime
                )}{" "}
                <br />
                <span
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {flightData?.Segments[1].DepFrom}{" "}
                </span>
              </Typography>

              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: "11px",
                  fontWeight: 500,
                }}
              >
                {flightData?.Segments[1]?.DepCountry} <br />
                {flightData?.Segments[1]?.MarkettingCarrier}{" "}
                {flightData?.Segments[1]?.MarkettingCarrierNumber}
                {/* {" & "}
                {transitCalculation(
                  flightData?.Segments[1]?.DepTime,
                  flightData?.Segments[1]?.ArrTime
                )} */}
                <br />
                {format(
                  new Date(flightData?.Segments[1]?.DepTime?.toString()),
                  "dd MMM yyyy hh:mm a"
                )}
                <br />
              </Typography>
            </React.Fragment>
          }
          followCursor
        >
          <Box>
            <Typography
              sx={{
                color: "var(--gray)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              {transitCalculation(arrTime, depTime)}
            </Typography>
            <Box className="stop-bar-parent">
              <CircleIcon
                sx={{ color: "var(--transit)", fontSize: "14px", zIndex: 2 }}
              />
              <CircleIcon
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "12px",
                  zIndex: 2,
                }}
              />

              <CircleIcon
                sx={{ color: "var(--transit)", fontSize: "14px", zIndex: 2 }}
              />
              <Box className="stop-bar-line"></Box>
            </Box>
            <Typography
              sx={{
                color: "var(--gray)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              1 STOP
            </Typography>
          </Box>
        </HtmlTooltip>
      ) : (
        <Box>
          <Typography
            sx={{
              color: "var(--gray)",
              fontWeight: 500,
              fontSize: {
                xs: "12px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            {transitCalculation(arrTime, depTime)}
          </Typography>
          <Box className="stop-bar-parent">
            <CircleIcon sx={{ color: "var(--transit)", fontSize: "14px" }} />
            <Box className="stop-bar-line"></Box>
            <CircleIcon sx={{ color: "var(--transit)", fontSize: "14px" }} />
          </Box>
          <Typography
            sx={{
              color: "var(--gray)",
              fontWeight: 500,
              fontSize: {
                xs: "12px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            NON STOP
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RoundTransit;

