import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import flightData from "./../flightData";
import { useNavigate, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SearchIcon from "@mui/icons-material/Search";
import { Calendar, DateRange } from "react-date-range";
import format from "date-fns/format";
import { styled } from "@mui/material/styles";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Swal from "sweetalert2";
import Grow from "@mui/material/Grow";
import { AiOutlineSwap } from "react-icons/ai";
import { IoIosPaperPlane } from "react-icons/io";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import secureLocalStorage from "react-secure-storage";
// import "../../Dashboard/DashboardMain/DashboardMain.css";
import "./Roundway.css";
import { addDays } from "date-fns";
// import ServerDown from "../../image/undraw/undraw_server_down_s-4-lk.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    // outline: "2px auto rgba(19,124,189,.6)",
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#003566",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--primary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const Roundway = ({
  tripType,
  iconColor,
  bgColor,
  borderColor,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  returningDate,
  setReturningDate,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  fSize,
}) => {
  // const data = flightData; // json data from flight Data

  const [query, setQuery] = useState("");
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://gallpax.flyjatt.com/v1/Duffel/AirPorts.php?name=${query}`
        );
        const json = await response.json();
        setData(json);
        setFromSuggest(json);
        setToSuggest(json);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (query.length >= 3) {
      fetchData();
    } else {
      setData([]);
    }
  }, [query]);

  //  show the form data when click input field
  const initialData = [
    {
      code: "NYC",
      name: "New York",
      Address: "New York, US",
    },
    {
      code: "JFK",
      name: "John F. Kennedy International Airport",
      Address: "New York, US",
    },
    {
      code: "LGA",
      name: "LaGuardia Airport",
      Address: "New York, US",
    },
    {
      code: "EWR",
      name: "Newark Liberty International Airport",
      Address: "Newark, US",
    },
    {
      code: "SWF",
      name: "New York Stewart International Airport",
      Address: "Newburgh, US",
    },
    {
      code: "NYS",
      name: "New York Skyports Seaplane Base Airport",
      Address: "New York, US",
    },
  ];
  const initialDataTo = [
    {
      code: "NOC",
      name: "Ireland West Airport Knock Airport",
      Address: "Knock, IE",
    },
    {
      code: "DUB",
      name: "Dublin Airport",
      Address: "Dublin, IE",
    },
    {
      code: "SNN",
      name: "Shannon Airport",
      Address: "Shannon, IE",
    },
    {
      code: "ORK",
      name: "Cork Airport",
      Address: "Cork, IE",
    },
    {
      code: "WAT",
      name: "Waterford Airport",
      Address: "Waterford, IE",
    },
    {
      code: "SXL",
      name: "Sligo Airport",
      Address: "Sligo, IE",
    },
  ];
  const [fromSuggest, setFromSuggest] = useState(initialDataTo);
  const [toSuggest, setToSuggest] = useState(initialData);

  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  // Date picker
  const [openDate, setOpenDate] = useState(false);
  const [openReturnDate, setOpenReturnDate] = useState(false);

  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state

  // handle click function
  const handleClickAwayCalender = () => {
    setOpenDate(false);
  };

  const handleSwapBtn = () => {
    setfaddress(toAddress);
    setToAddress(faddress);
    setFromSendData(toSendData);
    setToSendData(fromSendData);
    setFromSearchText(toSearchText);
    setToSearchText(fromSearchText);
  };

  //formOnChange Filter
  const formOnChange = (e) => {
    const value = e.target.value;
    setOpen(false);
    setQuery(value);
    if (value) {
      setFromSuggest(data);
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromSuggestedText = (name, code, address) => {
    setFromSearchText(name + "   " + "(" + code + ")");
    setFromSuggest([]);
    setFromSendData(code);
    setfaddress(address);
    setOpenFrom(false);
    setOpenTo(true);
    setOpen(false);
  };
  //ToOnChange filter

  const toOnChange = (e) => {
    const value = e.target.value;
    setOpen(false);
    setQuery(value);
    if (value) {
      setToSuggest(data);
    } else {
      setToSuggest(initialDataTo);
    }
  };

  const toSuggestedText = (name, code, address) => {
    setToSearchText(name + "   " + "(" + code + ")");
    setToSuggest([]);
    setToSendData(code);
    setToAddress(address);
    setOpenTo(false);
    setOpenDate(true);
  };
  //FromgetSuggetion
  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          className="box-index-oneway"
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "var(--secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--gray)",
                      color: "#fff",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box className="hover-color">
                      <span
                        style={{
                          fontSize: "12px",
                          // color: "#003566",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#fff",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          className="box-index-oneway"
          sx={{
            maxHeight: "230px",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "var(--secondary-color)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--gray)",
                      color: "#fff",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box className="hover-color">
                      <span
                        style={{
                          fontSize: "12px",
                          // color: "#003566",
                          display: "block",
                          textAlign: "left",
                        }}
                      >
                        {item.Address}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "#fff",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // SearchingField End

  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpenReturnDate(false);
  };
  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpenReturnDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  const handleClose = () => {
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
    }
  }

  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function adult2Inclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
    }
  }

  function adult2Decrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
      }
    }
  }

  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
    }
  }
  // Search Flight button click

  async function handleSearch(e) {
    e.preventDefault();
    //todo: setChangeState for reTrigger useEffect
    setChangeState((prev) => !prev);
    //todo: setChangeState for reTrigger useEffect end
    setClick(true);
    !user?.user?.email && navigate("/user/roundsearchresult");
    secureLocalStorage.setItem("search-data", {
      faddress,
      toAddress,
      departureDate: format(new Date(from), "dd MMM yy"),
      returningDate: format(new Date(to), "dd MMM yy"),
      adultCount,
      childCount,
      infant,
      tripType,
      fromSendData,
      toSendData,
      fromSearchText,
      toSearchText,
      className,
    });
    setClick(false);
    navigate("/user/roundsearchresult", {
      state: {
        faddress,
        toAddress,
        departureDate: format(new Date(from), "dd MMM yy"),
        returningDate: format(new Date(to), "dd MMM yy"),
        adultCount,
        childCount,
        infant,
        tripType,
        fromSendData,
        toSendData,
        fromSearchText,
        toSearchText,
        className,
        changeState,
      },
    }).catch((err) => {
      console.error(err.message);
      Swal.fire({
        imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        html: "<strong>Please contact us at support@flyfarint.com or 01755-572098, 09606912912.</strong>",
        confirmButtonText: "Search Again...",
        confirmButtonColor: "var(--primary-color)",
      }).then(() => {
        setClick(false);
        navigate(0);
      });
    });
  }

  // const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
  //   if (!changeFrom) {
  //     setFrom(startDate);
  //     setTo(startDate);
  //     if (startDate !== endDate) {
  //       setTo(endDate);
  //       setOpenDate(false);
  //       setTimeout(() => setOpen(true), 200);
  //     }
  //   } else {
  //     setTo(startDate);
  //     setChangeFrom(false);
  //     setOpenDate(false);
  //     setTimeout(() => setOpen(true), 200);
  //   }
  // });
  // const handleSelect = useCallback(({ selection: { startDate, endDate } }) => {
  //   setFrom(startDate);
  //   setTo(startDate);
  //   if (startDate !== endDate) {
  //     setTo(endDate);
  //     setOpenDate(false);
  //     setTimeout(() => setOpen(true), 200);
  //   }
  // });

  // const ranges = useMemo(() => {
  //   return [
  //     {
  //       startDate: from,
  //       endDate: to,
  //       key: "selection",
  //     },
  //   ];
  // }, [from, to]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        className="search-body-trip"
        sx={{ position: "relative", borderRadius: "5px" }}
      >
        <form onSubmit={handleSearch}>
          <Grid
            sx={{ justifyContent: "space-between" }}
            container
            rowSpacing={0}
            columnSpacing={0}
          >
            <Grid
              item
              className="dashboard-main-input-parent"
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{
                position: "relative",
                height: "50px",
                borderRight: "1px solid #DEDEDE",
              }}
            >
              <Box
                className="update-search1"
                bgcolor={bgColor}
                onClick={() => {
                  setOpenFrom((prev) => !prev);
                  setOpenTo(false);
                  setOpenDate(false);
                  setOpenReturnDate(false);
                  setOpen(false);
                }}
              >
                <Box>
                  {/* <p>From</p>
                  {faddress ? (
                    <span className="addressTitle">
                      {" "}
                      {faddress?.split(",")[0]}
                    </span>
                  ) : (
                    <span className="addressTitle">Dhaka</span>
                  )} */}
                  <span className="addressTitle">From</span>
                </Box>
                <Box
                  style={{
                    lineHeight: "0px",
                  }}
                >
                  <input
                    autoFocus
                    required
                    readOnly
                    value={fromSearchText}
                    placeholder="Hazrat Shahjalal International Airport"
                  />
                </Box>
              </Box>
              {/* <Grow in={openFrom}> */}
              {openFrom && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    width: "100%",
                    backgroundColor: "#fff",
                    height: "fit-content",
                    marginTop: "-5px",
                    borderRadius: "5px",

                    zIndex: "10",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // paddingLeft: "10px",
                      color: "#003566",
                      zIndex: 10,
                    }}
                    backgroundColor="#fff"
                    mt={"-45px"}
                  >
                    {/* <SearchIcon /> */}
                    <input
                      autoFocus
                      autoComplete="off"
                      onChange={formOnChange}
                      placeholder="Search a city..."
                      className="crimsonPlaceholder"
                      style={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        paddingLeft: "20px",
                        width: "100%",
                        height: "40px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                  </Box>
                  <Box width={"full"}>{fromGetSuggetion()}</Box>
                </Box>
              )}
              {/* </Grow> */}
              {/* <Box
                className="swap-btn2"
                onClick={handleSwapBtn}
                sx={{
                  display: { lg: "block", md: "block", sm: "none", xs: "none" },
                  zIndex: 11,
                }}
              >
                <AiOutlineSwap style={{ color: "#fff", fontSize: "20px" }} />
              </Box> */}
            </Grid>

            <Grid
              className="dashboard-main-input-parent"
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{
                position: "relative",
                height: "50px",
                borderRight: "1px solid #DEDEDE",
              }}
            >
              <Box
                className="update-search1"
                bgcolor={bgColor}
                onClick={() => {
                  setOpenTo((prev) => !prev);
                  setOpenFrom(false);
                  setOpenDate(false);
                  setOpenReturnDate(false);
                  setOpen(false);
                }}
              >
                <Box style={{ position: "relative" }}>
                  {/* <p>To</p> */}

                  <span className="addressTitle">
                    {/* {toAddress?.split(",")[0]} */}
                    To
                    {faddress?.split(",")[0] === toAddress?.split(",")[0] && (
                      <Stack
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          width: "100%",
                        }}
                      >
                        <Alert
                          icon={<ErrorOutlineIcon fontSize="inherit" />}
                          severity="error"
                          sx={{ fontSize: "11px" }}
                        >
                          Can't choose same place!
                        </Alert>
                      </Stack>
                    )}
                  </span>
                </Box>
                <Box
                  style={{
                    lineHeight: "0px",
                  }}
                >
                  <input
                    required
                    readOnly
                    value={toSearchText}
                    placeholder="Dubai International Airport"
                  />
                </Box>
              </Box>
              {/* <Grow in={openTo}> */}
              {openTo && (
                <Box
                  style={{
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    width: "100%",
                    backgroundColor: "#fff",
                    height: "fit-content",
                    marginTop: "-5px",
                    borderRadius: "5px",
                    zIndex: "10",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // paddingLeft: "12px",
                      color: "#003566",
                      zIndex: 10,
                    }}
                    backgroundColor="#fff"
                    mt={"-45px"}
                  >
                    {/* <SearchIcon /> */}
                    <input
                      autoComplete="off"
                      autoFocus
                      onChange={toOnChange}
                      className="crimsonPlaceholder"
                      placeholder="Search a city..."
                      style={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        paddingLeft: "20px",
                        width: "100%",
                        height: "40px",
                        backgroundColor: "transparent",
                        border: "none",
                      }}
                    />
                  </Box>
                  <Box>{toGetSuggetion()}</Box>
                </Box>
              )}
              {/* </Grow> */}
            </Grid>

            <Grid
              className="dashboard-main-input-parent"
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              style={{
                position: "relative",
                height: "50px",
                borderRight: "1px solid #DEDEDE",
              }}
            >
              <Box bgcolor={bgColor}>
                <Box
                  className="dashboard-main-input date12"
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    marginTop: "8px",
                    transition: "all 0.5s ease-in-out",
                    px:1
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "50%",
                    }}
                    onClick={() => {
                      setOpenDate((prev) => !prev);
                      setOpenReturnDate(false);
                      setOpenFrom(false);
                      setOpenTo(false);
                      setOpen(false);
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#8c8080",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      Depart &#10507;
                    </Typography>

                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: `${fSize || "20px"}`,
                      }}
                    >{`${format(new Date(from), "dd MMM yy")}`}</Typography>

                    {/* <Typography
                      variant="subtitle2"
                      style={{ color: "#003566", fontSize: "11px" }}
                    >
                      {`${format(new Date(from), "EEEE")}`}
                    </Typography> */}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      width: "50%",

                    }}
                    onClick={() => {
                      setOpenDate(false);
                      setOpenReturnDate((prev) => !prev);
                      setOpenFrom(false);
                      setOpenTo(false);
                      setOpen(false);
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#8c8080",
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "10px",
                      }}
                    >
                      Return &#10507;{" "}
                    </Typography>

                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: `${fSize || "20px"}`,
                      }}
                    >{`${format(new Date(from), "dd MMM yy")}`}</Typography>

                    {/* <Typography
                      variant="subtitle2"
                      style={{ color: "#003566", fontSize: "11px" }}
                    >
                      {`${format(new Date(to), "EEEE")}`}
                    </Typography> */}
                  </Box>
                </Box>
              </Box>
              <Grow in={openDate}>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      sm: "block",
                      xs: "block",
                    },
                    background: "var(--white)",
                    flexFlow: "column nowrap",
                  }}
                  className="new-dashboard-calendar"
                >
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      color: "var(--primary-color) !important",
                    }}
                  >
                    Travel Date
                  </Typography>
                  <Calendar
                    color={"var(--primary-color)"}
                    date={new Date(from)}
                    onChange={(date) => {
                      setFrom(date);
                      setOpenDate(false);
                      setOpenReturnDate(true);
                      setTimeout(() => setOpenReturnDate(true), 200);
                    }}
                    months={1}
                    minDate={new Date()}
                  />
                </Box>
              </Grow>
              <Grow in={openReturnDate}>
                <Box
                  sx={{
                    display: {
                      md: "flex",
                      sm: "flex",
                      xs: "flex",
                    },
                    background: "var(--white)",
                    flexFlow: "column nowrap",
                  }}
                  className="return-dashboard-calendar"
                >
                  <Typography
                    sx={{
                      fontSize: "14px !important",
                      color: "var(--primary-color) !important",
                    }}
                  >
                    Return Date
                  </Typography>
                  <Calendar
                    color={"var(--primary-color)"}
                    date={new Date(to)}
                    onChange={(date) => {
                      setTo(date);
                      setOpenReturnDate(false);
                      setTimeout(() => setOpen(true), 200);
                    }}
                    months={1}
                    minDate={new Date(from)}
                  />
                </Box>
              </Grow>

              {/* {openDate && (
                <Box>
                  <Box
                    sx={{
                      border: "2px solid red",
                      display: {
                        lg: "block",
                        md: "block",
                        sm: "none",
                        xs: "none",
                      },
                    }}
                  >
                    <DateRange
                      onChange={handleSelect}
                      direction="horizontal"
                      moveRangeOnFirstSelection={false}
                      retainEndDateOnFirstSelection={false}
                      months={2}
                      ranges={ranges}
                      rangeColors={["var(--primary-color)"]}
                      minDate={new Date()}
                      className="new-return-date-range"
                    />
                  </Box>
                  <Box
                    sx={{
                      border: "2px solid red",
                      display: {
                        lg: "none",
                        md: "none",
                        sm: "block",
                        xs: "block",
                      },
                    }}
                  >
                    <DateRange
                      onChange={handleSelect}
                      direction="vertical"
                      moveRangeOnFirstSelection={false}
                      retainEndDateOnFirstSelection={false}
                      months={2}
                      ranges={ranges}
                      rangeColors={["var(--primary-color)"]}
                      minDate={new Date()}
                      className="new-return-date-mobile"
                    />
                  </Box>
                </Box>
              )} */}
            </Grid>

            <Grid
              className="dashboard-main-input-parent "
              item
              xs={12}
              sm={12}
              md={4}
              lg={1.6}
              style={{
                height: "50px",
              }}
            >
              <Box className="update-search1" bgcolor={bgColor}>
                <Box className="traveler-count" onClick={handleClickOpen}>
                  {/* <Button
                    sx={{
                      justifyContent: "flex-start",
                      color: "#000",
                      display: "block",
                    }}
                  > */}
                  <p>Travelers & Class</p>
                  <span> {result} Traveler</span>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "#003566",
                      fontSize: "11px",
                      lineHeight: "10px",
                    }}
                  >
                    {className}
                  </Typography>
                  {/* </Button> */}
                </Box>
                {/* {open && ( */}
                <Grow in={open}>
                  <Box>
                    <Box
                      className="dialog-box"
                      sx={{
                        backgroundColor: "#fff",
                        padding: "5px 10px 20px 10px",
                        overflow: "hidden",
                        width: "285px",
                        zIndex: "999",
                      }}
                    >
                      <Box className="passenger-h3">
                        <h3>Passenger</h3>
                      </Box>
                      <Box className="dialog-flex">
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                          className="dialog-content"
                        >
                          <Box className="dialog-content">
                            <h5>{adultCount}</h5>
                          </Box>
                          <Box>
                            <h5>Adult</h5>
                            <span style={{ fontSize: "13px" }}>12+ yrs</span>
                          </Box>
                        </Box>
                        <Box className="incre-decre">
                          <button onClick={adultDecrement}>-</button>
                          <button onClick={adultInclement}>+</button>
                        </Box>
                      </Box>

                      <Box className="dialog-flex-child">
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                          className="dialog-content"
                        >
                          <Box className="dialog-content">
                            <h5>{childCount}</h5>
                          </Box>
                          <Box>
                            <h5>Children</h5>
                            <span style={{ fontSize: "13px" }}>
                              2- less than 12 yrs
                            </span>
                          </Box>
                        </Box>
                        <Box className="incre-decre">
                          <button onClick={adult2Decrement}>-</button>

                          <button onClick={adult2Inclement}>+</button>
                        </Box>
                      </Box>

                      <Box className="dialog-flex-infant">
                        <Box
                          sx={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                          }}
                          className="dialog-content"
                        >
                          <Box className="dialog-content">
                            <h5>{infant}</h5>
                          </Box>
                          <Box>
                            <h5>Infant</h5>
                            <span style={{ fontSize: "13px" }}>
                              0 - 23 month{" "}
                            </span>
                          </Box>
                        </Box>
                        <Box className="incre-decre">
                          <button onClick={infantDecrement}>-</button>

                          <button onClick={infantIncrement}>+</button>
                        </Box>
                      </Box>
                      <Box className="hr-line"></Box>
                      <Box className="new-passengerBtn">
                        <Box>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={className}
                              row
                              onChange={handleClassName}
                            >
                              <FormControlLabel
                                value="Economy"
                                control={<BpRadio />}
                                label="Economy"
                                sx={{
                                  mr: "21px",
                                }}
                              />
                              <FormControlLabel
                                value="Business"
                                control={<BpRadio />}
                                label="Business"
                              />
                              <FormControlLabel
                                value="First Class"
                                control={<BpRadio />}
                                label="First Class"
                              />
                              <FormControlLabel
                                value="Premium Economy "
                                control={<BpRadio />}
                                label="Premium Economy"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                        <Box position={"relative"}>
                          <Button
                            id="passengerSaveBtn"
                            size="small"
                            variant="contained"
                            color="error"
                            onClick={handleClose}
                            className="shine-effect"
                            sx={{
                              width: "100%",
                              bgcolor: "var(--primary-color)",
                              "&:hover": {
                                background: "var(--primary-color)",
                              },
                            }}
                          >
                            DONE
                          </Button>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Grow>
                {/* )} */}
              </Box>
            </Grid>
            <Grid
              lg={1.4}
              md={2}
              sm={12}
              xs={12}
              justifyContent="center"
              alignItems={"center"}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  disabled={
                    faddress?.split(",")[0] === toAddress?.split(",")[0] &&
                    !click
                      ? true
                      : faddress?.split(",")[0] !== toAddress?.split(",")[0] &&
                        click
                      ? true
                      : false
                  }
                  variant="contained"
                  endIcon={<ArrowRightAltIcon />}
                  className="shine-effect"
                  sx={{
                    height: "40px",
                    width: {
                      lg: "90%",
                      md: "90%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                    backgroundColor: "var(--primary-color)",
                    color: "#fff",
                    textTransform: "capitalize",
                    display: "flex",
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box>{click ? "Wait..." : "Search"}</Box>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </ClickAwayListener>
  );
};

export default Roundway;
