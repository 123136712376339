import React from "react";
import { Box, Typography, Grid, Container, Stack } from "@mui/material";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const ClientReview = () => {
  return (
    <Container sx={{ bgcolor: "#fff", p: { xs: 1, sm: 1 } }}>
      <Grid container columnSpacing={1}>
        {[...new Array(4)].map((item, index) => (
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ bgcolor: "var(--drawer-bgcolor)", p: 1 }}>
              <Stack direction="row" spacing={3}>
                <Box>
                  {[...new Array(5)].map((item, index) => (
                    <StarBorderOutlinedIcon
                      sx={{
                        color: "var(--fontcolor)",
                        bgcolor: "green",
                        p: 0.3,
                        fontSize: 20,
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                  ))}
                </Box>
                <Typography
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: {
                      xs: 14,
                    },
                    fontWeight: 500,
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  <CheckCircleIcon sx={{ fontSize: 20 }} />
                  Invited
                </Typography>
              </Stack>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: 12,
                  fontWeight: 600,
                  my: 1,
                }}
              >
                Efficieant site and Works with your Budget !
              </Typography>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: 11,
                  fontWeight: 500,
                  mb: 4,
                }}
              >
                The Process was simple and straight forward <br />
                They found my best flight options which...
              </Typography>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                Mr. jetofro{" "}
                <span style={{ color: "var(--gray)", fontWeight: 500 }}>
                  2 Hours ago
                </span>
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Box sx={{ pt: 3, pb: 1, textAlign: "center" }}>
        <Typography
          sx={{
            color: "var(--gray)",
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          Rated{" "}
          <span style={{ color: "var(--secondary-color)", fontWeight: 600 }}>
            4.6 / 5{" "}
          </span>
          based on 14385 reviews showing our 5 star reviews
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          color="var(--secondary-color)"
          fontWeight="500"
          justifyContent="center"
        >
          <StarBorderOutlinedIcon
            sx={{
              color: "green",
              fontSize: { xs: 16, sm: 18, md: 20 },
              fontWeight: 500,
              mr: 0.5,
            }}
          />
          Trustpilot
        </Stack>
      </Box>
    </Container>
  );
};

export default ClientReview;
