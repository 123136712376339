import { Autocomplete, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import EveryoneMarkup from "./EveryoneMarkup";
import SingleAgent from "./SingleAgent";

const Flight = () => {
  const [value, setValue] = useState("Everyone");
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          padding: "50px 0",
          background: "#fff",
          mt: { lg: 1, md: 2, sm: 6, xs: 7 },
          borderRadius: "15px",
          // bgcolor:'green',
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              // width: "95%",
              height: "fit-content",
              // bgcolor:'red',
              margin: "5px 20px",
            }}
          >
            <RadioGroup
              row
              value={value}
              onChange={handleChange}
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "var(--secondary-color)",
                  fontWeight: "500",
                  fontSize: { lg: "16px", md: "18px", sm: "12px", xs: "9px" },
                },
              }}
            >
              <FormControlLabel
                value="Everyone"
                control={
                  <Radio
                    sx={{
                      color: "var(--gray)",
                      "&.Mui-checked": {
                        color: "var(--primary-color)",
                      },
                    }}
                  />
                }
                label="Everyone"
              />
              {/* <FormControlLabel
                value="Single Agent"
                control={
                  <Radio
                    sx={{
                      color: "var(--gray)",
                      "&.Mui-checked": {
                        color: "var( --file-color)",
                      },
                    }}
                  />
                }
                label="Single Agent"
              /> */}
            </RadioGroup>
          </Box>
          <TabPanel value="Everyone">
            <EveryoneMarkup />
          </TabPanel>

          <TabPanel value="Single Agent">
            <SingleAgent />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Flight;
