import React from "react";
import { Box, Grid, Typography, Stack, Tooltip, Button } from "@mui/material";
import moment from "moment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HistoryToggleOffSharpIcon from "@mui/icons-material/HistoryToggleOffSharp";
import commaNumber from "comma-number";
import CircleIcon from "@mui/icons-material/Circle";
import { format } from "date-fns";

const FlightDetails = ({
  flightData,
  allflightdata,
  //   index,
  adultCount,
  childCount,
  infant,
  tripType,
}) => {
  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}m`;
    return transit;
  };
  return (
    <Box>
      <Typography
        sx={{
          backgroundColor: "var(--primary-color)",
          padding: "4px 0 4px 3vw",
          color: "var(--white)",
          fontWeight: 500,
          width: { xs: "100%", sm: "30%", md: "25%" },
        }}
      >
        Flight Details
      </Typography>
      <Box
        bgcolor="var(--bgcolor)"
        margin={{ xs: "15px", md: "2vw 3vw" }}
        padding="8px 20px"
      >
        {flightData?.Segments?.map((items, index, arr) => (
          <Box>
            {items.map((data, index, arr) => (
              <Box my={2}>
                <Grid container spacing={2}>
                  <Grid item xs={5} sm={6} md={2.5}>
                    <Box
                      width={{ xs: "20px", sm: "40px" }}
                      height={{ xs: "20px", sm: "40px" }}
                    >
                      <img
                        src={`https://assets.duffel.com/img/airlines/for-light-background/full-color-logo/${data?.MarkettingCarrier}.svg`}
                        alt="flight logo"
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: "12px",
                      }}
                      noWrap
                    >
                      {data?.MarkettingCarrierName}
                      <br />
                      <span
                        style={{
                          color: "var(--fontcolor)",
                        }}
                      >
                        {data?.MarkettingCarrier}
                        {"-"}
                        {data?.MarkettingCarrierNumber}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item xs={7} sm={6} md={3.5}>
                    <Box>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: {
                            xs: 12,
                            sm: 16,
                            md: 20,
                          },
                        }}
                      >
                        {data?.DepCountry?.split(" ,")[0]}
                        {", "}
                        <span style={{ fontSize: "12px" }}>
                          {data?.DepFrom}
                        </span>
                      </Typography>
                      <Tooltip title={`${data?.DepAirport}`}>
                        <Typography
                          sx={{
                            color: "var(--white)",
                            fontSize: "13px",
                          }}
                          noWrap
                        >
                          {data?.DepAirport}
                        </Typography>
                      </Tooltip>

                      <Typography
                        sx={{
                          color: "var(--fontcolor)",
                          fontSize: "12px",
                        }}
                      >
                        {new Date(data?.DepTime).toLocaleString("en-uk", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={5} sm={6} md={2.5} margin="auto">
                    <Box textAlign="center">
                      <Tooltip title="Flight Duration">
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 500,
                            fontSize: {
                              xs: "10px",
                              md: "12px",
                            },
                          }}
                        >
                          {data?.Duration?.split("PT")[1]}
                        </Typography>
                      </Tooltip>
                      <Box className="stop-bar-parent">
                        <CircleIcon
                          sx={{
                            color: "var(--transit)",
                            fontSize: "15px",
                          }}
                        />
                        <Box className="stop-bar-line-details"></Box>
                        <CircleIcon
                          sx={{
                            color: "var(--transit)",
                            fontSize: "15px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={7} sm={6} md={3.5}>
                    <Box>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: {
                            xs: 12,
                            sm: 16,
                            md: 20,
                          },
                        }}
                      >
                        {data?.ArrCountry?.split(" ,")[0]}
                        {", "}
                        <span style={{ fontSize: "12px" }}>{data?.ArrTo}</span>
                      </Typography>
                      <Tooltip title={`${data?.ArrAirport}`}>
                        <Typography
                          sx={{
                            color: "var(--white)",
                            fontSize: "13px",
                          }}
                          noWrap
                        >
                          {data?.ArrAirport}
                        </Typography>
                      </Tooltip>

                      <Typography
                        sx={{
                          color: "var(--fontcolor)",
                          fontSize: "12px",
                        }}
                      >
                        {new Date(data?.ArrTime).toLocaleString("en-uk", {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: false,
                        })}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <Typography
                      sx={{
                        color: "var(--white)",
                        fontSize: 10,
                        textAlign: "left",
                      }}
                    >
                      Model: {data?.AircraftModel}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} textAlign={{ xs: "left", md: "right" }}>
                    <Typography
                      sx={{
                        color: "var(--white)",
                        fontSize: 10,
                      }}
                    >
                      operated by{": "}
                      {data?.OperatingCarrierName}
                    </Typography>{" "}
                  </Grid>
                </Grid>
                <Box
                  sx={{
                    display: `${index - (arr?.length - 1) ? "block" : "none"}`,
                    // width: "100%",
                    // height: "0.5px",
                    // bgcolor: "var(--primary-color)",
                    position: "relative",
                    py: 2,
                  }}
                >
                  <Typography
                    sx={{
                      bgcolor: "var(--white)",
                      position: "absolute",
                      px: 2,
                      width: { xs: "80%", sm: "50%" },
                      top: "50%",
                      left: "50%",
                      translate: "-50%  -50%",
                      borderRadius: 2,
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                    Layover&nbsp;Time&nbsp;
                    {transitCalculation(
                      items[index + 1]?.DepTime,
                      items[index - 1 + 1]?.ArrTime
                    )}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
      </Box>

      <Box>
        <Typography
          sx={{
            backgroundColor: "var(--primary-color)",
            padding: "4px 0 4px 3vw",
            color: "var(--white)",
            fontWeight: 500,
            width: { xs: "100%", sm: "30%", md: "25%" },
          }}
        >
          Baggage
        </Typography>
        <Box
          bgcolor="var(--bgcolor)"
          margin={{ xs: "15px", md: "2vw 3vw" }}
          padding="8px 20px"
        >
          <Box className="flight-search-table">
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <tr>
                <th>Baggage</th>
                <th>Check-In</th>
                <th>Cabin</th>
              </tr>

              <tr>
                <td>Adult</td>
                <td>
                  {flightData?.Segments[0][0]?.Baggage[0]?.type || ""}&nbsp;
                  {flightData?.Segments[0][0]?.Baggage[0].quantity || "1"}
                  &nbsp;{"p"}
                </td>
                <td>7 Kg</td>
              </tr>
              {childCount > 0 && (
                <tr>
                  <td>Child</td>
                  <td>
                    {flightData?.Segments[0][0]?.Baggage[0]?.type || ""}{" "}
                    {flightData?.Segments[0][0]?.Baggage[0].quantity || "1"}
                    &nbsp;{"p"}
                  </td>
                  <td>7 Kg</td>
                </tr>
              )}
              {infant > 0 && (
                <tr>
                  <td>Infant</td>
                  <td>
                    {/* {flightData?.Segments[0]?.Baggage[0]
                                      ?.type || ""} */}
                    {/* {flightData?.Segments[0].Baggage[0]
                                      .quantity || ""} */}
                    0 {" p"}
                  </td>
                  <td>7 Kg</td>
                </tr>
              )}
            </table>
          </Box>
        </Box>
      </Box>

      <Box>
        <Typography
          sx={{
            backgroundColor: "var(--primary-color)",
            padding: "4px 0 4px 3vw",
            color: "var(--white)",
            fontWeight: 500,
            width: { xs: "100%", sm: "30%", md: "25%" },
          }}
        >
          Fare Policy
        </Typography>
        <Box
          bgcolor="var(--bgcolor)"
          margin={{ xs: "15px", md: "2vw 3vw" }}
          padding="8px 20px"
        >
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Cancellation
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Refund Amount = Paid Amount - Airline Cancellation Fee
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Re-issue
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Re-issue Fee = Airline’s Fee + Fare Difference
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Refund
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Refund Amount = Paid Amount - Airline Cancellation Fee
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Void
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Re-issue Fee = Airline’s Fee + Fare Difference
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* -------new end */}
    </Box>
  );
};

export default FlightDetails;
