import { Box, Typography } from "@mui/material";
import React from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { LandingHeader } from "../LandingHeader/LandingHeader";

const ComingSoon = () => {
  return (
    <Box>
      <LandingHeader />
      <Typography
        sx={{
          fontSize: "2.5vw",
          color: "#fff",
          fontWeight: 500,
          textAlign: "center",
          mt: 10,
          mb: 5,
        }}
      >
        Contact us on WhatsApp (+8801685370455) for Hotels
      </Typography>
      <Footer />
    </Box>
  );
};

export default ComingSoon;
