import { Navigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const PrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const usercheck = user?.data[0]?.email;

  return children;
};

export default PrivateRoute;
