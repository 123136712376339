import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { format, addDays } from "date-fns";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import commaNumber from "comma-number";

const AdminLedgerReport = () => {
  //user data fetch from localStorage
  const [users, setUsers] = useState("");
  useEffect(() => {
    const users = secureLocalStorage.getItem("admin-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  let agentID = users?.data[0]?.customerId;
  let subagentId = users?.user?.subagentId;

  const [ledgerData, setLedgerData] = useState([]);
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    let url = `https://api.flyfarint.com/v.1.0.0/WhiteLabel/MyAccount/Ledger.php?agentId=${agentID}&endDate=${new Date(
      endDate
    ).toLocaleDateString("sv")}&startDate=${new Date(
      startDate
    ).toLocaleDateString("sv")}`;
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLedgerData(data);
      });
  };
  return (
    <Box>
      <Container>
        <Box
          sx={{
            margin: "0px 0px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 500,
              fontSize: "24px",
              margin: "10px 0px",
              color: "#222222",
              fontFamily: "poppins",
            }}
          >
            Report
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box className="passengerInput1">
            <Grid container spacing={4}>
              <Grid item md={4}>
                <Typography>Start Date</Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    onChange={(e) => setStartDate(e.target.value)}
                    type="date"
                    placeholder="Find traveler to auto fill"
                    required
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Typography>End Date</Typography>
                <Box style={{ marginTop: "5px" }}>
                  <input
                    onChange={(e) => setEndDate(e.target.value)}
                    type="date"
                    placeholder="Find traveler to auto fill"
                    required
                  />
                </Box>
              </Grid>
              <Grid item md={4}>
                <Button
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    textTransform: "capitalize",
                    borderRadius: "2px",
                    background: "#222222",
                    color: "#FFFFFF",
                    width: "370px",
                    mt: "1rem",
                    "&:hover": {
                      backgroundColor: "#222222",
                    },
                  }}
                  type="submit"
                >
                  Proceed
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>

        {ledgerData.length !== 0 ? (
          <Box className="balance-transaction" marginTop={"20px"}>
            <table width="100%">
              <tr>
                <th width="15%">Transaction Id</th>
                <th width="10%">Ledger Type</th>
                <th width="10%">Company Name </th>
                <th width="15%">Transaction Date</th>
                <th width="35%">Transaction Details</th>
                <th width="15%">Transaction Amount</th>
                <th width="10%">Last Balance</th>
              </tr>

              {ledgerData.length !== 0 &&
                ledgerData?.map((data) => (
                  <tr>
                    <td>
                      {data?.deposit > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--deposit)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Deposit
                        </button>
                      ) : data?.purchase > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--purchase)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            height: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Purchase
                        </button>
                      ) : data?.loan > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--loan)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Loan
                        </button>
                      ) : data?.void > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--void)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Void
                        </button>
                      ) : data?.refund > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--refund)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Refund
                        </button>
                      ) : data?.reissue > "0" ? (
                        <button
                          style={{
                            backgroundColor: "var(--reissue)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Reissue
                        </button>
                      ) : (
                        <button
                          style={{
                            backgroundColor: "var(--return)",
                            padding: "4px 0px",
                            border: "none",
                            width: "100%",
                            color: "var(--white)",
                          }}
                        >
                          Return
                        </button>
                      )}
                    </td>
                    <td></td>
                    <td>{data?.reference}</td>

                    <td>
                      {data?.createdAt !== ""
                        ? format(
                            new Date(data?.createdAt),
                            "dd MMM yyyy hh:mm a"
                          )
                        : "Transaction Date"}
                    </td>
                    <td>{data?.details || "-"}</td>
                    <td>
                      {data?.deposit > "0"
                        ? "+" + commaNumber(Number(data?.deposit))
                        : data?.purchase > "0"
                        ? "-" + commaNumber(Number(data?.purchase))
                        : data?.returnMoney > "0"
                        ? "+" + commaNumber(Number(data?.returnMoney))
                        : ""}{" "}
                      Tk
                    </td>

                    <td>
                      {commaNumber(data?.lastAmount) || "Remaining Balance"} Tk
                    </td>
                  </tr>
                ))}
            </table>
          </Box>
        ) : (
          <Box mt={2}>
            <Typography style={{ background: "#ccc", padding: "4px 8px" }}>
              No Records found...!
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default AdminLedgerReport;
