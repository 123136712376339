import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";

import Modal from "@mui/material/Modal";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  Pagination,
  ClickAwayListener,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import Swal from "sweetalert2";
import { addDays } from "date-fns/esm";
import { format } from "date-fns";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import secureLocalStorage from "react-secure-storage";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import searchModal from "../../images/searchmodal.jpg";
import SingleFlight from "../../components/SingleFlight/SingleFlight";
import OneWayFilter from "../../components/OneWayFilter";
import OneWayFilterDrawer from "../../components/OneWayFilterDrawer";
// import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import Commission from "../../components/Commission";
import Preloader from "../../components/Preloader/Preloader";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import styled from "@emotion/styled";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import "../SearchReslut/SearchResult.css";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import Header from "../../components/Header/Header";
import Footer from "./../../components/Footer/Footer";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import SearchModal from "./SearchModal";
import AirlineSlider from "../../components/SingleFlight/AirlineSlider";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  boxShadow: 24,
  border: "none",
  outlne: "none",
  padding: 0,
  margin: 0,
  pb: 2,
  background: "#fff",
  borderRadius: "10px",
  overflow: "hidden",
};

// const HtmlTooltip = styled(({ className, ...propss }) => (
//   <Tooltip {...propss} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#dc143c",
//     maxWidth: 220,
//     fontSize: "20px",
//     borderRadius: "8px 0px 8px 0px",
//   },
// }));

const modalStyle = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
  height: "fit-content",
  padding: "20px",
  background: "var(--third-color)",
  borderRadius: "10px",
  boxShadow: "none",
};

const SearchResult = () => {
  const commissionData = secureLocalStorage.getItem("commissionData");
  const [noData, setNoData] = useState("No Data");
  const searchData = secureLocalStorage.getItem("search-data");
  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location?.state
      : secureLocalStorage?.getItem("search-data");

  const {
    toSendData,
    adultCount,
    childCount,
    departureDate,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    fromSendData,
    className,
  } = requiredSearchData;

  //all states that i have to send to modify search
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for retrigger useEffect
  const [changeStateSession, setChangeStateSession] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change

  const [options, setOptions] = useState({
    showCalendarAvailability: false,
    directFlightOnly: false,
    includeCheckedBaggage: false,
  });
  const handleClose = () => setIsLoaded(false);
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [oneWayFromSearchText, setOneWayFromSearchText] =
    useState(fromSearchText);
  const [oneWayToSearchText, setOneWayToSearchText] = useState(toSearchText);

  const now = useRef(new Date(departureDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSearchDate, setFromSearchDate] = useState(new Date(departureDate));
  const [oneWayFaddress, setOneWayFaddress] = useState(faddress);
  const [oneWayToAddress, setOneWayToAddress] = useState(toAddress);
  const [oneWayFromSendData, setOneWayFromSendData] = useState(fromSendData);
  const [oneWayToSendData, setOneWayToSendData] = useState(toSendData);
  const [oneWayAdultCount, setOneWayAdultCount] = useState(adultCount);
  const [oneWayChildCount, setOneWayChildCount] = useState(childCount);
  const [oneWayInfant, setOneWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [oneWayClassName, setOneWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  //end

  //CM Box States

  const [openCm, setOpenCm] = useState(false);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  const [defaultCommissionRate, setDefaultCommissionRate] = useState(7);
  const [defaultCommissionRateAmount, setDefaultCommissionRateAmount] =
    useState(0);
  const [customerFare, setCustomerFare] = useState(true);

  //end
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [searchDate, setSearchDate] = useState(
    new Date(from).toLocaleDateString("sv")
  );
  const [data, setData] = useState(null);
  const [data2, setData2] = useState([]);

  const [departureLocation, setDepartureLocation] = useState("");
  const departureLocationCode =
    location?.state?.fromSendData?.slice(1, 5) ||
    searchData.fromSendData?.slice(1, 5);

  const [arrivalLocation, setArrivalLocation] = useState("");

  // todo:next day previous day variables
  let tomorrow = new Date(fromSearchDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let yesterday = new Date(fromSearchDate);
  yesterday.setDate(yesterday.getDate() - 1);

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(data?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // get the target element to toggle
  const handleClickAway = () => {};

  let body = {
    Segment: [
      {
        DepFrom: `${fromSendData?.replace(/\s+/g, "")}`,
        ArrTo: toSendData,
        DepDate: `${new Date(departureDate).toLocaleDateString("sv")}`,
      },
    ],
    tripType: tripType,
    Adult: adultCount,
    Child: childCount,
    Infant: infant,
    Class: oneWayClassName,
  };
  useEffect(() => {
    modifyHandleClose();

    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://gallpax.flyjatt.com/v1/Duffel/AirSearch.php",

          // "https://gallpax.flyjatt.com/v1/Duffel/AirSearch.php",
          {
            method: "POST",
            body: JSON.stringify(body),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          if (data.length > 0 || data.message !== "No Flight Data Found") {
            setIsLoaded(false);
            const uniqueData = data;
            const count = uniqueData.length;
            const pageNumber = Math.ceil(count / size);
            setPageCount(pageNumber);
            setData(uniqueData);
            setData2(uniqueData);
            handleClose();
          } else {
            throw new Error("No Flights Found");
          }
        } else {
          throw new Error("Request Failed");
        }
      } catch (error) {
        handleClose();
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Error",
          text: "An error occurred while fetching flight data. Please try again.",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/");
        });
      }
    };
    fetchData();
  }, [
    changeState,
    changeStateSession,
    size,
    // fromSearchDate,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    navigate,
    isLoaded,
  ]);

  if (isLoaded) {
    return (
      <Box>
        <Box
          sx={{
            ...style,
            "&:focus": {
              outline: "none",
            },
          }}
        >
          <SearchModal />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: "var(--third-color)" }}>
      <LandingHeader />{" "}
      <Container position="relative">
        <Box mt={4}>
          <Box mb={2}>
            <AirlineSlider
              data={data}
              setData={setData}
              filteredData={data2}
              setfilteredData={setData2}
            />
          </Box>
          <Grid container columnSpacing={2}>
            <Grid
              item
              sx={{
                p: 0,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
              }}
              lg={2.7}
              width="100%"
            >
              <Box>
                <OneWayFilter
                  data={data}
                  setData={setData}
                  filteredData={data2}
                  setfilteredData={setData2}
                  noData={noData}
                  setNoData={setNoData}
                  departureDate={departureDate}
                  setFrom={setFrom}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={9.3} width="100%">
              <Box
                sx={{
                  bgcolor: "var(--white)",
                  overflow: "hidden",
                  borderRadius: "10px",
                }}
              >
                <Grid container columnSpacing={3} alignItems="center">
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      p: { xs: 1, sm: 1, md: 2 },
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                        textAlign: "center",
                      },
                    }}
                  >
                    Cheapest
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      p: { xs: 1, sm: 1, md: 2 },
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                        textAlign: "center",
                      },
                    }}
                  >
                    Quickest
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      p: { xs: 1, sm: 1, md: 2 },
                      borderRight: {
                        xs: 0,
                        sm: 0,
                        md: "1px solid var(--gray)",
                        textAlign: "center",
                      },
                    }}
                  >
                    Lowest&nbsp;Emissions
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={3}
                    sx={{
                      p: { xs: 1, sm: 1, md: 2 },
                      cursor: "pointer",
                      textAlign: "center",
                      bgcolor: "var(--result)",
                      color: "var(--white)",
                    }}
                    onClick={() => modifyHandleOpen()}
                  >
                    Modify&nbsp;Search
                  </Grid>
                </Grid>
              </Box>
              <Box textAlign="center" my={2}>
                Showing <strong>{data?.length}</strong> Flight
              </Box>
              <Grid container>
                {/* //todo: show search result section*/}

                {/* //todo:main search result */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    height: "fit-content",
                  }}
                >
                  {data2?.slice(0, size).map((data, index) => {
                    return (
                      <SingleFlight
                        key={index}
                        flightData={data}
                        tripType={tripType}
                        adultCount={adultCount}
                        childCount={childCount}
                        infant={infant}
                        from={fromSendData}
                        to={toSendData}
                        fromAddress={faddress}
                        toAddress={toAddress}
                        dDate={searchDate}
                        agentFarePrice={agentFarePrice}
                        setAgentFarePrice={setAgentFarePrice}
                        commisionFarePrice={commisionFarePrice}
                        setCommisionFarePrice={setCommisionFarePrice}
                        customerFare={customerFare}
                        setCustomerFare={setCustomerFare}
                      />
                    );
                  })}
                </Grid>
                {/* //todo: pagination*/}
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={pageCount}
                        onChange={handlePageChange}
                        shape="rounded"
                        color="primary"
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Modal open={modifyOpen} onClose={modifyHandleClose}>
          <Container>
            <Box sx={modalStyle}>
              <FlightSearchBox
                options={options}
                setOptions={setOptions}
                type={type}
                setType={setType}
                value={value}
                setValue={setValue}
                fromSearchText={oneWayFromSearchText}
                setFromSearchText={setOneWayFromSearchText}
                toSearchText={oneWayToSearchText}
                setToSearchText={setOneWayToSearchText}
                from={from}
                setFrom={setFrom}
                to={to}
                setTo={setTo}
                faddress={oneWayFaddress}
                setfaddress={setOneWayFaddress}
                toAddress={oneWayToAddress}
                setToAddress={setOneWayToAddress}
                fromSendData={oneWayFromSendData}
                setFromSendData={setOneWayFromSendData}
                toSendData={oneWayToSendData}
                setToSendData={setOneWayToSendData}
                adultCount={oneWayAdultCount}
                setAdultCount={setOneWayAdultCount}
                childCount={oneWayChildCount}
                setChildCount={setOneWayChildCount}
                infant={oneWayInfant}
                setInfant={setOneWayInfant}
                result={result}
                setResult={setResult}
                className={oneWayClassName}
                setClassName={setOneWayClassName}
                changeState={changeState}
                setChangeState={setChangeState}
                changeFrom={changeFrom}
                setChangeFrom={setChangeFrom}
              />
            </Box>
          </Container>
        </Modal>
      </Container>
      <Footer />
    </Box>
  );
};

export default SearchResult;
