import {
  Button,
  Collapse,
  Grid,
  Stack,
  SwipeableDrawer,
  Tab,
  Tabs,
  // ToggleButton,
  // ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import React, { useState } from "react";
import seat1 from "../../images/Icon/bag.svg";
import bag from "../../images/Icon/seat.svg";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useNavigate } from "react-router-dom";
import toimg from "../../images/Icon/to.svg";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import FlightIcon from "@mui/icons-material/Flight";
import commaNumber from "comma-number";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import WorkIcon from "@mui/icons-material/Work";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import MuiToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";

import "./SingleFlight.css";
import RoundTransit from "./RoundTransit";
import Transit from "./Transit";
import moment from "moment";
import FlightShow from "./FlightShow";
import FlightDetails from "./FlightDetails";

const ToggleButton = styled(MuiToggleButton)(({ selectedColor }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    color: "white",
    backgroundColor: selectedColor,
  },
}));

// const HtmlTooltip = styled(({ className, ...propss }) => (
//   <Tooltip {...propss} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "var(--gray)",
//     maxWidth: 220,
//     fontSize: "5px",
//     padding: "10px",
//   },
// }));

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--transit)",
    maxWidth: 600,
    padding: "8px",
  },
}));

const RoundSingleFlight = (props) => {
  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(false);
  const [allFlights, setAllFlight] = useState(true);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [toggleBtn, setToggleBtn] = useState("depart");
  const handleChangeToggleBtn = (event, newValue) => {
    setToggleBtn(newValue);
  };

  const {
    backarrival,
    backarrivalDate,
    backarrivalTime,
    backdeparture,
    backdepartureDate,
    backdepartureTime,
    backflightduration,
    bags,
    career,
    careerName,
    goarrival,
    goarrivalDate,
    goarrivalTime,
    godeparture,
    godepartureDate,
    godepartureTime,
    goflightduration,
    refundable,
    price,
    Taxes,
    seat,
    segment,
    segments,
    stop,
    system,
    transit,
    bookingcode,
    BasePrice,
    pricebreakdown,
  } = props.flightData;
  const { from, to, fromAddress, toAddress } = props;

  const flightData = props.flightData;
  console.log(props.flightData);

  const {
    adultCount,
    childCount,
    infant,
    tripType,
    agentFarePrice,
    setAgentFarePrice,
    commisionFarePrice,
    setCommisionFarePrice,
    customerFare,
    setCustomerFare,
  } = props;

  //todo:CF AF CM variable are here
  const [toggledrawer, setToggledrawer] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const commissionData = secureLocalStorage.getItem("commissionData");
  const clientPrice = Math.round(
    parseInt(props.flightData.clientPrice || props.flightData.baseprice)
  );
  const percentRate = parseInt(7) / 100;
  const clientFare = parseInt(clientPrice);
  const agentFare = Math.round(parseInt(price));
  const commission = Math.round(clientFare - agentFare);
  //todo:end of CM AF CM variables
  //todo: booking functional work here
  const FlightInformation = (id) => {
    console.log("id", id);
    const OfferId = secureLocalStorage.setItem("Offerid", {
      offerId: id,
      adultCount,
      childCount,
      infant,
      fromAddress,
      toAddress,
      tripType: "Return",
      from,
      to,
    });
    navigate("/user/flightinformation", {
      state: {
        flightData,
        adultCount,
        childCount,
        infant,
        tripType: "Return",
        fromAddress,
        toAddress,
        from,
        to,
      },
    });
  };

  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}min`;
    return transit;
  };
  const depTime = flightData?.Segments[0][0]?.DepTime;
  const arrTime =
    flightData?.Segments[0][flightData?.Segments[0].length - 1]?.ArrTime;
  const backdepTime = flightData?.Segments[1][0]?.DepTime;
  const backarrTime =
    flightData?.Segments[1][flightData?.Segments[1].length - 1]?.ArrTime;
  const allFlightData = flightData?.Segments[0];
  return (
    <Box mb={2}>
      <Box>
        {flightData?.Segments?.map((data) => (
          <FlightShow
            flightData={flightData}
            data={data}
            adultCount={adultCount}
            childCount={childCount}
            infant={infant}
          />
        ))}
      </Box>
      <Stack direction="row" justifyContent="flex-end">
        <Box>
          {" "}
          {["right"].map((anchor) => (
            <Box key={anchor}>
              <Button
                size="small"
                onClick={toggleDrawer(anchor, true)}
                sx={{
                  color: "var(--white)",
                  fontWeight: 500,
                  fontSize: "11px",
                  bgcolor: "var(--result)",
                  px: 2,
                  borderRadius: 0,
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  py: 0.5,
                  "&:hover": {
                    bgcolor: "var(--result)",
                  },
                }}
              >
                See Flight Details
                <PlayArrowIcon style={{ padding: "0", fontSize: "18px" }} />
              </Button>
              {/* ----new start flight details */}
              <SwipeableDrawer
                style={{ margin: "0px", padding: "0px" }}
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <Box
                  bgcolor="var(--secondary-color)"
                  sx={{ width: { xs: "85vw", sm: "70vw", md: "50vw" } }}
                >
                  <Box margin={{ xs: "15px", md: "2vw 0 2vw 3vw" }}>
                    <Typography
                      sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                      color="var(--white)"
                      fontWeight={500}
                    >
                      {allFlightData[0]?.DepCountry?.split(" ,")[0]} (
                      {allFlightData[0]?.DepFrom}){" - "}
                      {
                        allFlightData[
                          allFlightData?.length - 1
                        ]?.ArrCountry?.split(" ,")[0]
                      }{" "}
                      ({allFlightData[allFlightData?.length - 1]?.ArrTo})
                    </Typography>
                    <Typography color="var(--white)">
                      {tripType === "oneway"
                        ? "One Way"
                        : tripType === "return"
                        ? "Return"
                        : "Multi City"}{" "}
                      Flight <span>|</span>{" "}
                      {adultCount > 0 && `Adult(${adultCount})`}
                      {childCount > 0 && `Children(${childCount})`}
                      {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                      {format(
                        new Date(allFlightData[0]?.DepTime),
                        "dd MMM yyyy"
                      )}
                    </Typography>
                  </Box>
                  {/* -----Fight details--- */}
                  <Box>
                    <FlightDetails
                      flightData={flightData}
                      allFlightData={allFlightData}
                      adultCount={adultCount}
                      childCount={childCount}
                      infant={infant}
                    />
                  </Box>
                </Box>
              </SwipeableDrawer>
            </Box>
          ))}
        </Box>
        <Button
          size="small"
          sx={{
            color: "var(--white)",
            fontWeight: 500,
            backgroundColor: "var(--btn-color)",
            borderRadius: 0,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            px: 3,
            py: 1,
            "&:hover": {
              backgroundColor: "var(--btn-color)",
            },
          }}
          onClick={() => FlightInformation(flightData?.OfferId)}
        >
          Book Trip
        </Button>{" "}
      </Stack>
    </Box>
  );
};

export default RoundSingleFlight;
