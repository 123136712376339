import {
  Box,
  Typography,
  Button,
  Pagination,
  Stack,
  Tooltip,
  Modal,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
import commaNumber from "comma-number";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../BookingManagement/BookingRoute.css";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564B8",
      darker: "#dc143c",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const BookingAll = () => {
  const user = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  const [issueData, setIssueData] = useState([]);
  const [mainAgentData, setMainAgentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  let size = 20;
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setMainAgentData(issueData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    const url = `https://gallpax.flyjatt.com/v1/Queues/index.php?all&page=${page}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setMainAgentData(data?.data);
        setIssueData(data?.data);
      });
  }, [page]);

  //  search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;

    if (searchInput !== "") {
      const filterData = issueData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setMainAgentData(filterData);
    } else if (searchInput === "") {
      setMainAgentData(issueData);
    }
  };

  //  send to issue details page
  const sendToQueuesDetails = (data) => {
    navigate("/admin/qdetails", {
      state: {
        data,
      },
    });
  };

  return (
    <Box>
      <Box className="searchList1" my={2}>
        <input type="text" placeholder="search" onChange={handelSearchItems} />
      </Box>
      <Box className="balance-transaction" marginTop={"20px"}>
        <table>
          <tr>
            <th>Customer Id</th>
            <th>Booking Id</th>
            <th>Status</th>
            <th>Route</th>
            <th>PNR</th>
            <th>Book At</th>
            <th>PAX</th>
            <th>Sold Price</th>

            {/* <th>Contact </th> */}
          </tr>

          {mainAgentData.map((data) => (
            <tr>
              <td>
                <Typography
                  sx={{
                    bgcolor: "var(--white)",
                    color: "var(--secondary-color)",
                    cursor: "pointer",
                  }}
                  onClick={() => sendToQueuesDetails(data)}
                >
                  {data?.BookingId}
                </Typography>
              </td>{" "}
              <td>{data?.CustomerId}</td>
              <td>{data?.Status}</td>
              <td>
                {data?.DepFrom} - {data?.ArrTo}
              </td>
              <td>{data?.BookingRef}</td>
              <td>
                {data?.created_at
                  ? format(
                      new Date(data?.created_at.toString()),
                      "dd MMM yy hh:mm a"
                    )
                  : "Booking Time"}
              </td>
              <td>{data?.TotalPax}</td>
              <td>{data?.SellPrice} EUR </td>
              {/* <td style={{}}>
                <a href={`#`}>
                  <PhoneIcon
                    style={{
                      color: "var(--primary-color)",
                      fontSize: "20px",
                      marginRight: "5px",
                    }}
                  />
                </a>
                <a href={`#`} target="_blank" rel="noreferrer">
                  <WhatsAppIcon
                    style={{
                      color: "green",
                      fontSize: "21px",
                      marginRight: "5px",
                    }}
                  />
                </a>
              </td> */}
            </tr>
          ))}
        </table>
      </Box>

      <Box
        sx={{
          width: "100%",
          my: 3,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderTop: "1px solid var(--primary-color)",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            my: 3,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

            marginTop: "8px",
          }}
        >
          <Typography style={{ fontSize: "15px" }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                //   count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default BookingAll;
