import { Container, Grid, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Logo from "../../../images/Logo.png";

const Control = () => {
  const navigate = useNavigate();
  const [markuptype, setMarkupType] = useState("percent");
  const [markup, setMarkup] = useState();
  const [currency, setCurrency] = useState("BTN");
  const [rate, setRate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [loadData, setLoadData] = useState({});
  console.log("markup", markuptype, markup);
  useEffect(() => {
    const url = "https://gallpax.flyjatt.com/v1/Control/index.php?data";
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setLoadData(data);
        setIsLoading(false);
      });
  }, []);
  console.log(loadData);
  const handleSubmit = async (e) => {
    setIsLoading(false);
    e.preventDefault();

    await fetch(
      `https://gallpax.flyjatt.com/v1/Control/index.php?markuptype=${markuptype}&markup=${markup}`,

      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          Swal.fire({
            // icon: "success",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Markup Add Successfully !",
            confirmButtonColor: "#dc143c",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        } else {
          Swal.fire({
            // icon: "success",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            text: "Invalid Information",
            confirmButtonColor: "#dc143c",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        }
      });

    e.target.reset();
  };
  const handleSubmitcurrency = async (e) => {
    setIsLoading(false);
    e.preventDefault();
    console.log(
      `https://gallpax.flyjatt.com/v1/Control/index.php?markuptype=${markuptype}&markup=${markup}`
    );

    await fetch(
      `https://gallpax.flyjatt.com/v1/Control/index.php?markuptype=&markup=&currency=${currency}&exchangerate=${rate}`,

      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          Swal.fire({
            // icon: "success",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Currency Change Successfully !",
            confirmButtonColor: "#dc143c",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        } else {
          Swal.fire({
            // icon: "success",
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            text: "Invalid Information",
            confirmButtonColor: "#dc143c",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        }
      });

    e.target.reset();
  };
  return (
    <Box
      style={{
        backgroundColor: "var(--secondary-color)",
        height: "100vh",
      }}
    >
      <Container>
        {/*----------- header -------------*/}
        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "200px",
                  }}
                >
                  Control
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Typography style={{ color: "#fff", fontSize: "18px" }}>
            Add Markup
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <Box className="searchList1-select" my={1}>
                  <select
                    style={{ width: "100%", height: "35px" }}
                    type="text"
                    required
                    onChange={(e) => setMarkupType(e.target.value)}
                  >
                    {/* <option value="">Select Markup Type</option> */}
                    <option value="percent">Parcentage</option>
                    <option value="amount">Amount</option>
                  </select>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Box className="searchList1" my={1}>
                  <input
                    style={{ width: "100%", height: "35px" }}
                    type="number"
                    required
                    placeholder={
                      markuptype === "amount"
                        ? "Minimum Value 1"
                        : "Minimum Value 0% to 100%"
                    }
                    onChange={(e) => setMarkup(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <button
                  style={{
                    width: "180px",
                    borderRadius: "3px",
                    height: "34px",
                    marginTop: "8px",
                    color: "#222222",
                    cursor: "pointer",
                  }}
                  type="submit"
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </form>
        </Box>
        {isLoading ? (
          "Loading..."
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} my={3}>
              <Typography style={{ color: "#fff", fontSize: "18px" }}>
                Added Markup Value
              </Typography>
              <Box className="balance-transaction">
                <table>
                  <thead>
                    <td width="50%">Markup Type</td>
                    <td width="50%">Value</td>
                  </thead>
                  <tbody>
                    <td width="50%">{loadData?.markuptype}</td>
                    <td width="50%">{loadData?.markup}</td>
                  </tbody>
                </table>
              </Box>
            </Grid>
          </Grid>
        )}

        <Box mt={4}>
          <form onSubmit={handleSubmitcurrency}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} md={4}>
                <Typography style={{ color: "#fff", fontSize: "18px" }}>
                  Currency
                </Typography>
                <Box className="searchList1-select" my={1}>
                  <select
                    style={{ width: "100%", height: "35px" }}
                    type="text"
                    required
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="BTN">BTN</option>
                    <option value="USD">USD</option>
                  </select>
                </Box>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Typography style={{ color: "#fff", fontSize: "18px" }}>
                  Exchange Rate{" "}
                </Typography>
                <Box className="searchList1" my={1}>
                  <input
                    style={{ width: "100%", height: "35px" }}
                    type="number"
                    name="currency"
                    placeholder="Exchange Rate value 1 to 1000"
                    onChange={(e) => setRate(e.target.value)}
                    disabled={currency === "USD"}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3} mt={4.5}>
                <button
                  style={{
                    width: "180px",
                    height: "34px",
                    color: "#222222",
                    cursor: "pointer",
                    borderRadius: "3px",
                  }}
                  type="submit"
                >
                  Save
                </button>
              </Grid>
            </Grid>
          </form>
          {isLoading ? (
            "Loading..."
          ) : (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4} my={3}>
                <Typography style={{ color: "#fff", fontSize: "18px" }}>
                  Added Exchange Currency
                </Typography>
                <Box className="balance-transaction">
                  <table>
                    <thead>
                      <td width="50%">Currency</td>
                      <td width="50%">Value</td>
                    </thead>
                    <tbody>
                      <td width="50%">{loadData?.currency}</td>
                      <td width="50%">{loadData?.exchangerate}</td>
                    </tbody>
                  </table>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default Control;
