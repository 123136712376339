import { Grid, Typography, Box, Stack } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import commaNumber from "comma-number";
import AddIcon from "@mui/icons-material/Add";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import { Container } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import "./FlightInfoDetails.css";
import { useState } from "react";

const FlightInfoDetails = ({
  loadData,
  flightData,
  tripType,
  adultCount,
  childCount,
  infant,
}) => {
  const users = secureLocalStorage.getItem("user-info");

  console.log("loadData", loadData);
  console.log("flightData", flightData);
  console.log("tripType", tripType);

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box style={{ marginTop: "20px" }}>
      {/*  //TODO:price break down start here */}
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          boxShadow: "none",
          borderRadius: "0px",
          border: "2px solid var(--gray)",
        }}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{
            background: "var(--gray)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              height: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Price Breakdown
            </Typography>
            <Box>
              {expanded === "panel1" ? (
                <RemoveIcon style={{ color: "#fff", fontSize: "25px" }} />
              ) : (
                <AddIcon style={{ color: "#fff", fontSize: "25px" }} />
              )}
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails className="flight-accordian2">
          <Box>
            <Typography
              style={{
                color: "var(--black)",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Total Payable
            </Typography>
            <Typography
              style={{
                color: "var(--black)",
                fontFamily: "poppins",
                fontSize: "22px",
                fontWeight: "500",
              }}
            >
              {flightData?.Currency} {commaNumber(loadData?.TotalFare)}
            </Typography>
          </Box>

          <Box my={2} height="2px" bgcolor="#DEDEDE"></Box>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total PAX
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {adultCount + childCount + infant}&#128100;
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total Base Fare
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {loadData?.Currency} {commaNumber(loadData?.BaseFare)}
            </Typography>
          </Grid>
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "#222222",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Total TAX
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--secondary-color)",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              {loadData?.Currency} {commaNumber(loadData?.Taxes)}
            </Typography>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {/* //TODO: price break down end here */}
      {/* //TODO: Baggage Policy */}
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          boxShadow: "none",
          borderRadius: "0px",
          borderLeft: "2px solid var(--gray)",
          borderRight: "2px solid var(--gray)",
          borderBottom: "2px solid var(--gray)",
        }}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={{
            background: "var(--gray)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              height: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Baggage
            </Typography>
            <Box>
              {expanded === "panel2" ? (
                <RemoveIcon style={{ color: "#fff", fontSize: "25px" }} />
              ) : (
                <AddIcon style={{ color: "#fff", fontSize: "25px" }} />
              )}
            </Box>
          </Box>
        </AccordionSummary>

        <AccordionDetails
          className="baggageTable1"
          style={{ marginTop: "15px" }}
        >
          {flightData?.Segments?.map((data) => (
            <>
              {data?.Baggage?.map((item) => (
                <Stack direction="row" justifyContent="space-between">
                  <Typography fontSize="14px">Type: {item?.type} </Typography>
                  <Typography fontSize="14px">
                    Quentity: {item?.quantity}
                  </Typography>
                </Stack>
              ))}
            </>
          ))}
        </AccordionDetails>
      </Accordion>
      {/* //TODO: End Baggage Policy */}
      {/* //Todo: Cancellation Policy Section */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
        style={{
          marginTop: "10px",
          marginBottom: "10px",
          boxShadow: "none",
          borderRadius: "0px",
          borderLeft: "2px solid var(--gray)",
          borderRight: "2px solid var(--gray)",
          borderBottom: "2px solid var(--gray)",
        }}
      >
        <AccordionSummary
          // expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
          id="panel2bh-header"
          style={{
            background: "var(--gray)",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              height: "20px",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: "#fff",
                fontFamily: "poppins",
                fontWeight: "500",
              }}
            >
              Policy
            </Typography>

            <Box>
              {expanded === "panel3" ? (
                <RemoveIcon style={{ color: "#fff", fontSize: "25px" }} />
              ) : (
                <AddIcon style={{ color: "#fff", fontSize: "25px" }} />
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Cancellation
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Refund Amount = Paid Amount - Airline Cancellation Fee
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Re-issue
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Re-issue Fee = Airline’s Fee + Fare Difference
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Refund
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Refund Amount = Paid Amount - Airline Cancellation Fee
            </Typography>
          </Box>
          <Box mt={2}>
            <Box
              style={{
                width: "130px",
                background: "var(--transit)",
                padding: "5px 15px",
              }}
            >
              <Typography
                style={{
                  fontSize: "13px",
                  color: "var(--primary-color)",
                  fontFamily: "poppins",
                  fontWeight: "500",
                }}
              >
                Void
              </Typography>
            </Box>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--gray)",
                fontFamily: "poppins",
                fontWeight: "500",
                marginTop: "5px",
                paddingLeft: "15px",
              }}
            >
              Re-issue Fee = Airline’s Fee + Fare Difference
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* //Todo: End Cancellation Policy Section */}
    </Box>
  );
};

export default FlightInfoDetails;
