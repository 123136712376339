import { Navigate, useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AdminPrivateRoute = ({ children, ...rest }) => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("admin-info");
  const usercheck = user?.data[0]?.email;

  return !usercheck || undefined ? (
    <Navigate to="/admin" state={{ from: location }} />
  ) : (
    children
  );
};

export default AdminPrivateRoute;
