import { Box } from "@mui/material";
import React from "react";

const MarkUpPercentDetail = () => {
  return (
    <Box>
      <Box className="markup-table" marginTop={"20px"}>
        <table>
          <tr>
            <th>Sl</th>
            <th>Type</th>
            <th>Company Name</th>
            <th>Agent Name</th>
            <th>Markup Type</th>
            <th>Markup Amount</th>
            <th>Operation</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Flight</td>
            <td>Pie Coder</td>
            <td>Afridi</td>
            <td>percentage</td>
            <td>100</td>
            <td>Edit</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Flight</td>
            <td>Pie Coder</td>
            <td>Afridi</td>
            <td>percentage</td>
            <td>100</td>
            <td>Edit</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Flight</td>
            <td>Pie Coder</td>
            <td>Afridi</td>
            <td>percentage</td>
            <td>100</td>
            <td>Edit</td>
          </tr>
        </table>
      </Box>
    </Box>
  );
};

export default MarkUpPercentDetail;
