import React from "react";
import { Box, Grid, Typography, Stack, Tooltip } from "@mui/material";
import moment from "moment";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HistoryToggleOffSharpIcon from "@mui/icons-material/HistoryToggleOffSharp";
import commaNumber from "comma-number";

const FlightShow = ({
  flightData,
  data,
  adultCount,
  childCount,
  infant,
  index,
  arr,
}) => {
  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}m`;
    return transit;
  };
  return (
    <Box>
      <Grid
        container
        sx={{
          bgcolor: "var(--card-color)",
          height: "fit-content",
        }}
      >
        <Grid
          item
          xs={12}
          ms={8}
          md={9.5}
          borderBottom={
            index === arr?.length - 1 ? "0" : "1px solid var(--gray)"
          }
        >
          <Grid container>
            <Grid item xs={4} sm={4} md={4}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Box
                  sx={{
                    height: { xs: "40px", sm: "50px", md: "70px" },
                    width: { xs: "40px", sm: "50px", md: "70px" },
                    p: { xs: 0.5, sm: 1 },
                  }}
                >
                  <img
                    src={flightData?.CarrierLogo}
                    alt={`${flightData.Caree}`}
                    width="100%"
                    height="100%"
                  />
                </Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <FlightTakeoffIcon
                    sx={{
                      fontSize: 25,
                      display: { xs: "none", sm: "none", md: "block" },
                    }}
                  />{" "}
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                      },
                    }}
                  >
                    {moment(data[0]?.DepTime).format("ddd, DD-MMM-YYYY")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4} sm={4} md={4} sx={{ position: "relative" }}>
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  bgcolor: "var(--third-color)",
                  clipPath:
                    "polygon(75% -1%, 85% 50%, 75% 101%, 0% 101%, 10% 50%, 0% -1%)",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Box pl="15%">
                  <Typography
                    sx={{
                      color: "var(--secondary-color)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "14px",
                      },
                    }}
                  >
                    {moment(data[0]?.DepTime).format("hh:mm, a")}
                  </Typography>

                  <Tooltip
                    title={`${data[0]?.DepCountry?.split(" ,")[0]}
                      ,
                      ${data[0]?.DepFrom}`}
                    followCursor
                  >
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                        },
                      }}
                      noWrap
                    >
                      {data[0]?.DepCountry?.split(" ,")[0]},{data[0]?.DepFrom}
                    </Typography>
                  </Tooltip>
                </Box>
              </Stack>
              <Box
                sx={{
                  position: "absolute",
                  top: "33%",
                  right: 0,
                  display: { xs: "none", sm: "block", md: "block" },
                }}
              >
                <Typography
                  sx={{
                    color: "var(--secondary-color)",
                    fontWeight: 500,
                    fontSize: "12px",
                    border: "1px solid var(--gray)",
                    bgcolor: "var(--white)",
                    px: 0.5,
                    py: 0.2,
                  }}
                >
                  {data?.length === 1 ? (
                    <>Non </>
                  ) : data?.length === 2 ? (
                    <>1 {data[1]?.DepFrom} </>
                  ) : data?.length === 3 ? (
                    <>
                      2 {data[1]?.DepFrom}, {data[2]?.DepFrom}{" "}
                    </>
                  ) : (
                    ""
                  )}
                  Stop
                </Typography>{" "}
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Grid
                container
                columnSpacing={1}
                alignItems="center"
                height="100%"
              >
                <Grid item xs={12} sm={7} textAlign="center">
                  <Box>
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "14px",
                        },
                      }}
                    >
                      {moment(data[data?.length - 1]?.ArrTime).format(
                        "hh:mm, a"
                      )}
                    </Typography>
                    <Tooltip
                      title={`${
                        data[data?.length - 1]?.ArrCountry?.split(" ,")[0]
                      }
                      ,
                      ${data[data?.length - 1]?.ArrTo}`}
                      followCursor
                    >
                      <Typography
                        sx={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                          },
                        }}
                        noWrap
                      >
                        {data[data?.length - 1]?.ArrCountry?.split(" ,")[0]},
                        {data[data?.length - 1]?.ArrTo}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sx={{ display: { xs: "none", sm: "block", md: "block" } }}
                >
                  <Stack direction="row" alignItems="center">
                    <HistoryToggleOffSharpIcon
                      sx={{
                        fontSize: 16,
                        color: "var(--primary-color)",
                      }}
                    />
                    &nbsp;
                    <Typography
                      sx={{
                        color: "var(--secondary-color)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "14px",
                        },
                      }}
                    >
                      {transitCalculation(
                        data[0]?.DepTime,
                        data[data.length - 1]?.ArrTime
                      )}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} ms={3} md={2.5} borderLeft="1px solid var(--gray)">
          <Stack
            direction={{ xs: "row", sm: "column", md: "column" }}
            justifyContent={"center"}
            px={2}
            pt={0.8}
            display={index === 0 ? "flex" : "none"}
          >
            <Typography
              sx={{
                color: "var(--result)",
                fontWeight: 600,
                fontSize: { xs: 16, sm: 18, md: 25 },
                textAlign: "end",
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: 500,
                  verticalAlign: "text-top",
                }}
              >
                {flightData?.Currency}{" "}
              </span>
              <span>{commaNumber(flightData?.TotalFare)}</span>
            </Typography>
            <Typography
              sx={{
                color: "var(--gray)",
                fontWeight: 500,
                fontSize: { xs: 12 },
                textAlign: "end",
              }}
            >
              {adultCount + childCount + infant}&nbsp;Travellar
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FlightShow;
