import { Box, Typography, Pagination, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import commaNumber from "comma-number";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564B8",
      darker: "#dc143c",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const AdminTransectionReturn = () => {
  const [ledgerData, setLedgerData] = useState([]);
  const [search, setSearch] = useState([]);

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 20;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setSearch(ledgerData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch(`https://gallpax.flyjatt.com/v1/Report/Ledger.php?all`)
      .then((res) => res.json())
      .then((data) => {
        const returnData = data.filter((data) => {
          return data?.returnMoney !== null;
        });
        const count = returnData.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setLedgerData(returnData);
        setSearch(returnData);
      });
  }, []);

  //  handle search
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = ledgerData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setSearch(filterData);
    } else if (searchInput === "") {
      setSearch(ledgerData);
    }
  };
  return (
    <div>
      <Box className="searchList1" my={2}>
        <input type="text" placeholder="search" onChange={handelSearchItems} />
      </Box>

      <Box className="balance-transaction" marginTop={"20px"}>
        <table>
          <tr>
            <th>Customer Id</th>
            <th>Ledger Type</th>
            <th>Transaction Dtae</th>
            <th>Transaction Dteails</th>
            <th>Amount</th>
            <th>Last Balance</th>
          </tr>

          {search?.slice(0, size).map((data) => (
            <tr>
              <td>
                <span
                  style={{
                    background: "var(--fontcolor)",
                    color: "var(--secondary-color)",
                    padding: "3px 20px",
                    boxSizing: "border-box",
                  }}
                >
                  {data?.customerId}
                </span>
              </td>
              <td>
                {data?.deposit > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#177c33",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Deposit
                  </button>
                ) : data?.purchase > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#ed1c24",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Purchase
                  </button>
                ) : data?.loan > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#ff6464",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Loan
                  </button>
                ) : data?.void > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#235f83",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Void
                  </button>
                ) : data?.refund > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#7a64ff",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Refund
                  </button>
                ) : data?.others > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#272323",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Other
                  </button>
                ) : data?.servicefee > "0" ? (
                  <button
                    style={{
                      backgroundColor: "#0b8668",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Service Fee
                  </button>
                ) : (
                  <button
                    style={{
                      backgroundColor: "orange",
                      color: "#fff",
                      padding: "5px",
                      border: "none",
                      borderRadius: "5px",
                      width: "100%",
                    }}
                  >
                    Issue Rejected
                  </button>
                )}
              </td>
              <td>
                {" "}
                {data?.created_at
                  ? format(
                      new Date(data?.created_at.toString()),
                      "dd MMM yy hh:mm a"
                    )
                  : "Booking Time"}
              </td>
              <td> {data?.details}</td>
              <td>
                {data?.deposit > "0"
                  ? "+" + commaNumber(Number(data?.deposit))
                  : data?.purchase > "0"
                  ? "-" + commaNumber(Number(data?.purchase))
                  : data?.returnMoney > "0"
                  ? "+" + commaNumber(Number(data?.returnMoney))
                  : data?.loan > "0"
                  ? "+" + commaNumber(Number(data?.loan))
                  : data?.void > "0"
                  ? "+" + commaNumber(Number(data?.void))
                  : data?.refund > "0"
                  ? "+" + commaNumber(Number(data?.refund))
                  : data?.reissue > "0"
                  ? "+" + commaNumber(Number(data?.reissue))
                  : data?.others > "0"
                  ? "+" + commaNumber(Number(data?.others))
                  : data?.servicefee > "0"
                  ? "+" + commaNumber(Number(data?.servicefee))
                  : ""}
                Tk
              </td>
              <td> {data?.lastAmount}</td>
            </tr>
          ))}
        </table>
      </Box>

      <Box
        sx={{
          width: "100%",
          my: 3,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          borderTop: "1px solid var(--primary-color)",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            my: 3,
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",

            marginTop: "8px",
          }}
        >
          <Typography style={{ fontSize: "15px" }}>
            Showing Results {page} - {pageCount}
          </Typography>
          <ThemeProvider theme={theme}>
            <Stack spacing={2}>
              <Pagination
                size="small"
                //   count={pageCount}
                onChange={handlePageChange}
                color="primary"
              />
            </Stack>
          </ThemeProvider>
        </Box>
      </Box>
    </div>
  );
};

export default AdminTransectionReturn;
