import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import FlightMarkUpDetail from "./FlightMarkUpDetail";
import MarkUpAmoutDetail from "./MarkUpAmoutDetail";
import MarkUpPercentDetail from "./MarkUpPercentDetail";
import './MarkUpDetails.css'

const FlightMarkup = () => {
  const [optionValue, setOptionValue] = useState("");
  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
  };
  return (
    <Box className="searchList2">
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Box  my={1}>
            <input type="text" placeholder="search" />
          </Box>
        </Grid>

        <Grid item xs={12} md={4} textAlign="right">
          <select onChange={handleChangeOption} >
            <option value="All">All</option>
            <option value="percentage">Percentage</option>
            <option value="amount">Amount</option>
          </select>
        </Grid>
      </Grid>

      {(optionValue === "All" || optionValue === "") && (
        <>
          {" "}
          <FlightMarkUpDetail />{" "}
        </>
      )}
      {optionValue === "percentage" && (
        <>
          <MarkUpPercentDetail />
        </>
      )}
      {optionValue === "amount" && (
        <>
          <MarkUpAmoutDetail />
        </>
      )}
    </Box>
  );
};

export default FlightMarkup;
