import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import HomeSearchBox from "../../components/HomeSearchBox/HomeSearchBox";
import { LandingHeader } from "../../components/LandingHeader/LandingHeader";
import "./home.css";
import FeedBack from "./FeedBack";
import HotDeals from "./HotDeals";
import bgImage from "../../images/bg.jpg";
import ClientReview from "./ClientReview";

const styles = {
  boxContainer: {
    backgroundImage: `url(${bgImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    position: "relative",
  },
  text: {
    textAlign: "center",
    color: "balck",
    position: "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
};

const Home = () => {
  return (
    <Box>
      <Box sx={{ ...styles.boxContainer, minHeight: "100vh" }}>
        <Box mb={2}>
          <LandingHeader />
        </Box>

        <Box sx={{ ...styles.text, width: "100%" }}>
          <Box textAlign={"left"}>
            <HomeSearchBox />
          </Box>
        </Box>
      </Box>
      <Box mt={{ xs: 3, sm: 3 }}>
        <ClientReview />
      </Box>
      <Box pb={6}>
        <HotDeals />
      </Box>
      {/* <Box pt={4}>
        <FeedBack />
      </Box> */}
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
