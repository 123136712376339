import { Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./ProfileTabbar.css";
import { LandingHeader } from "./../../LandingHeader/LandingHeader";
import Footer from "./../../Footer/Footer";
import useAuthentication from "../../../hooks/useAuthentication";

const ProfileTabbar = () => {
  const { logout } = useAuthentication();

  return (
    <Box>
      <LandingHeader />
      <Container>
        <Box mt={5} sx={{ display: "block", flexWrap: "wrap" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <NavLink
              to="/profile"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ fontSize: "14px" }}
            >
              My Profile
            </NavLink>
            <NavLink
              to="/booking"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ fontSize: "14px" }}
            >
              My Booking
            </NavLink>
            <NavLink
              to="/paymennt"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ fontSize: "14px" }}
            >
              Payment
            </NavLink>
            <NavLink
              to="/ledger"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ fontSize: "14px" }}
            >
              Ledger
            </NavLink>
            <NavLink
              onClick={logout}
              to="/"
              className={({ isActive }) => (isActive ? "active-link" : "link")}
              style={{ fontSize: "14px" }}
            >
              Log Out
            </NavLink>

            <Outlet></Outlet>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default ProfileTabbar;
