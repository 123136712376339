import React from "react";
import { Box, Typography, Stack, Grid, Container } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from "@mui/material/Tab";

import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import a from "../../images/HotDeals/a.webp";
import b from "../../images/HotDeals/b.webp";
import c from "../../images/HotDeals/c.webp";
import d from "../../images/HotDeals/d.webp";
import e from "../../images/HotDeals/e.webp";
import f from "../../images/HotDeals/f.webp";
import n from "../../images/HotDeals/n.webp";

const HotDeals = () => {
  const [value, setValue] = React.useState("a");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container sx={{ p: { xs: 1 } }}>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} sm={12}>
          <Box>
            <TabContext value={value}>
              <Box
                sx={{
                  bgcolor: "transparent",
                  color: "#FFF",
                  width: {
                    lg: "fit-content",
                    md: "fit-content",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: 2,
                  marginLeft: { xs: 0, sm: 0, md: 0 },
                  ".MuiTabs-root button": {
                    backgroundColor: "var(--white)",
                    color: "var(--primary-color)",
                    opacity: 1,
                    // borderRadius: "10px",
                  },
                  "button.Mui-selected": {
                    fontWeight: 500,
                    borderBottom: "none !important",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                  },
                  ".MuiTabs-indicator": {
                    display: "none !important",
                  },
                  ".MuiTabs-root button:last-child": {
                    // border-top-left-radius: ;

                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                  },
                  ".MuiTabs-root button:first-child": {
                    // border-top-left-radius: ;

                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                    borderTopLeftRadius: "20px",
                    borderBottomLeftRadius: "20px",
                  },
                }}
              >
                <TabList
                  value={value}
                  onChange={handleChange}
                  aria-label="Dashboard Tabs"
                  variant="scrollable"
                >
                  <Tab
                    label="POPULAR"
                    value="a"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },
                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />

                  <Tab
                    label="EUROPE"
                    value="b"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },

                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />

                  <Tab
                    label="AMERICA"
                    value="c"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },

                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />
                  <Tab
                    label="ASIA"
                    value="d"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },

                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />
                  <Tab
                    label="OCEANIA"
                    value="e"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },

                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />
                  <Tab
                    label="AFRICA"
                    value="F"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 15px" },
                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />
                </TabList>
              </Box>
            </TabContext>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={4}>
          <Stack direction={"row"} justifyContent="flex-end">
            <TabContext value={value}>
              <Box
                sx={{
                  bgcolor: "transparent",
                  color: "#FFF",
                  width: {
                    lg: "fit-content",
                    md: "fit-content",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: 2,
                  marginLeft: { xs: 0, sm: 0, md: 0 },
                  ".MuiTabs-root button": {
                    backgroundColor: "var(--white)",
                    color: "var(--primary-color)",
                    opacity: 1,
                    borderRadius: "30px",
                  },
                  "button.Mui-selected": {
                    fontWeight: 500,
                    borderBottom: "none !important",
                    backgroundColor: "var(--primary-color)",
                    color: "var(--white)",
                  },
                  ".MuiTabs-indicator": {
                    display: "none !important",
                  },
                }}
              >
                <TabList
                  value={value}
                  onChange={handleChange}
                  aria-label="Dashboard Tabs"
                  variant="scrollable"
                >
                  <Tab
                    label="One-Way"
                    value="f"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 50px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },
                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />

                  <Tab
                    label="All Year"
                    value="g"
                    sx={{
                      p: { xs: "2px 10px", md: "2px 50px" },
                      marginRight: { Xs: 0, sm: 0, md: 0.1 },

                      fontSize: { xs: "11px", sm: "14px" },
                    }}
                  />
                </TabList>
              </Box>
            </TabContext>
          </Stack>
        </Grid> */}
      </Grid>

      <Grid
        container
        columnSpacing={2}
        spacing={2}
        sx={{ height: "100%", overflow: "hidden", mt: 1 }}
      >
        <Grid
          item
          xs={12}
          sm={9.5}
          sx={{ height: { sm: "440px", xs: "auto" } }}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3} md={3}>
                <Box sx={{ position: "relative" }}>
                  <img
                    src={f}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--white)",
                          fontWeight: 600,
                        }}
                      >
                        New York
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        United States
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box position="relative">
                  <img
                    src={a}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--white)",
                          fontWeight: 600,
                        }}
                      >
                        Paris{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        France
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box position="relative">
                  <img
                    src={b}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--white)",
                          fontWeight: 600,
                        }}
                      >
                        Lisban
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        Partugal
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box position="relative">
                  <img
                    src={b}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--white)",
                          fontWeight: 600,
                        }}
                      >
                        New York
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        United States
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Box position="relative">
                  <img
                    src={c}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--secondary-color)",
                          fontWeight: 600,
                        }}
                      >
                        Dubai
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        United Arab Amirat
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Box position="relative">
                  <img
                    src={f}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "fill",
                      verticalAlign: "middle",
                    }}
                  />
                  <Stack
                    direction={"column"}
                    justifyContent="space-between"
                    p={2}
                    sx={{ position: "absolute", top: 0, height: "100%" }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: "var(--secondary-color)",
                          fontWeight: 600,
                        }}
                      >
                        THAILAND
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 500,
                          color: "var(--secondary-color)",
                          bgcolor: "var(--drawer-bgcolor)",
                          py: 0.5,
                          px: 1,
                        }}
                      >
                        thailand36
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: 18,
                        color: "var(--white)",
                        fontWeight: 500,
                      }}
                    >
                      From &euro;213
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2.5}
          sx={{ height: { sm: "440px", xs: "auto" } }}
        >
          <Box position="relative">
            <img
              src={n}
              style={{
                width: "100%",
                height: "100%",
                // objectFit: "fill",
                // verticalAlign: "middle",
              }}
            />
            <Stack
              direction={"column"}
              justifyContent="space-between"
              p={2}
              sx={{ position: "absolute", top: 0, height: "95%" }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: 20,
                    color: "var(--white)",
                    fontWeight: 600,
                  }}
                >
                  Paris
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "var(--secondary-color)",
                    bgcolor: "var(--drawer-bgcolor)",
                    py: 0.5,
                    px: 1,
                  }}
                >
                  United States
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: 18,
                  color: "var(--white)",
                  fontWeight: 500,
                }}
              >
                From &euro;213
              </Typography>
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default HotDeals;
