/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container, Stack } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import seat from "../../images/Icon/seat.svg";
import bag from "../../images/Icon/bag.svg";
import { useNavigate } from "react-router-dom";
import { TabContext, TabPanel } from "@material-ui/lab";
import FlightIcon from "@mui/icons-material/Flight";
import commaNumber from "comma-number";
import secureLocalStorage from "react-secure-storage";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import WorkIcon from "@mui/icons-material/Work";
import { format } from "date-fns";
import moment from "moment";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import "./SingleFlight.css";
import Transit from "./Transit";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import HistoryToggleOffSharpIcon from "@mui/icons-material/HistoryToggleOffSharp";
import FlightShow from "./FlightShow";
import FlightDetails from "./FlightDetails";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--gray)",
    maxWidth: 300,
    padding: "10px",
  },
}));

const SingleFlight = ({
  flightData,
  adultCount,
  childCount,
  infant,
  to,
  from,
  tripType,
  fromAddress,
  toAddress,
  dDate,
  agentFarePrice,
  setAgentFarePrice,
  commisionFarePrice,
  setCommisionFarePrice,
  customerFare,
  setCustomerFare,
}) => {
  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(false);
  const [toggledrawer, setToggledrawer] = useState(false);
  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const [changeStateSession, setChangeStateSession] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const clientPrice = parseInt(
    flightData.system !== "Galileo" ? flightData.clientPrice : flightData.price
  );
  // const percentRate = parseInt(commissionData.defaultCommissionRate) / 100;
  const clientFare = Math.round(clientPrice);
  const agentFare = Math.round(
    parseInt(
      flightData.system !== "Galileo" ? flightData.price : flightData.BasePrice
    )
  );
  const commission = Math.round(clientFare - agentFare);

  console.log("flightData", flightData?.OfferId);

  const navigate = useNavigate();
  const FlightInformation = (id) => {
    console.log("id", id);
    const OfferId = secureLocalStorage.setItem("Offerid", {
      offerId: id,
      adultCount,
      childCount,
      infant,
      fromAddress,
      toAddress,
      tripType,
      from,
      to,
    });
    navigate("/user/flightinformation", {
      state: {
        flightData,
        adultCount,
        childCount,
        infant,
        tripType: "Oneway",
        fromAddress,
        toAddress,
        from,
        to,
      },
    });
  };

  let calParcent = (num, percentage) => {
    const result = num * (percentage / 100);
    return parseFloat(result.toFixed(0));
  };
  let percntVal = calParcent(parseInt(flightData.price), 7);

  const offerPrice = parseInt(flightData.price) + parseInt(percntVal);
  const PaxCount = adultCount + childCount + infant;
  let count = [];
  for (let i = 0; i < PaxCount; i++) {
    count.push(i);
  }

  const transitCalculation = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}m`;
    return transit;
  };
  const allFlightData = flightData?.Segments[0];

  // ----   --------Copy form ALL.js end----------
  return (
    <Box mb={2}>
      <Box>
        {flightData?.Segments?.map((data, index, arr) => (
          <FlightShow
            index={index}
            arr={arr}
            flightData={flightData}
            data={data}
            adultCount={adultCount}
            childCount={childCount}
            infant={infant}
          />
        ))}
      </Box>
      <Stack direction="row" justifyContent="flex-end">
        <Box>
          {" "}
          {["right"].map((anchor) => (
            <Box key={anchor}>
              <Button
                size="small"
                onClick={toggleDrawer(anchor, true)}
                sx={{
                  color: "var(--white)",
                  fontWeight: 500,
                  fontSize: "11px",
                  bgcolor: "var(--result)",
                  px: 2,
                  borderRadius: 0,
                  borderBottomLeftRadius: "5px",
                  borderBottomRightRadius: "5px",
                  py: 0.5,
                  "&:hover": {
                    bgcolor: "var(--result)",
                  },
                }}
              >
                See Flight Details
                <PlayArrowIcon style={{ padding: "0", fontSize: "18px" }} />
              </Button>
              {/* ----new start flight details */}
              <SwipeableDrawer
                style={{ margin: "0px", padding: "0px" }}
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                <Box
                  bgcolor="var(--secondary-color)"
                  sx={{ width: { xs: "85vw", sm: "70vw", md: "50vw" } }}
                >
                  <Box margin={{ xs: "15px", md: "2vw 0 2vw 3vw" }}>
                    <Typography
                      sx={{ fontSize: { xs: "12px", sm: "20px" } }}
                      color="var(--white)"
                      fontWeight={500}
                    >
                      {allFlightData[0]?.DepCountry?.split(" ,")[0]} (
                      {allFlightData[0]?.DepFrom}){" - "}
                      {
                        allFlightData[
                          allFlightData?.length - 1
                        ]?.ArrCountry?.split(" ,")[0]
                      }{" "}
                      ({allFlightData[allFlightData?.length - 1]?.ArrTo})
                    </Typography>
                    <Typography color="var(--white)">
                      {tripType === "oneway"
                        ? "One Way"
                        : tripType === "return"
                        ? "Return"
                        : "Multi City"}{" "}
                      Flight <span>|</span>{" "}
                      {adultCount > 0 && `Adult(${adultCount})`}
                      {childCount > 0 && `Children(${childCount})`}
                      {infant > 0 && `Infant(${infant})`} <span>|</span>{" "}
                      {format(
                        new Date(allFlightData[0]?.DepTime),
                        "dd MMM yyyy"
                      )}
                    </Typography>
                  </Box>
                  {/* -----Fight details--- */}
                  <Box>
                    <FlightDetails
                      flightData={flightData}
                      allFlightData={allFlightData}
                      adultCount={adultCount}
                      childCount={childCount}
                      infant={infant}
                    />
                  </Box>
                </Box>
              </SwipeableDrawer>
            </Box>
          ))}
        </Box>
        <Button
          size="small"
          sx={{
            color: "var(--white)",
            fontWeight: 500,
            backgroundColor: "var(--btn-color)",
            borderRadius: 0,
            borderBottomLeftRadius: "5px",
            borderBottomRightRadius: "5px",
            px: 3,
            py: 1,
            "&:hover": {
              backgroundColor: "var(--btn-color)",
            },
          }}
          onClick={() => FlightInformation(flightData?.OfferId)}
        >
          Book Trip
        </Button>{" "}
      </Stack>
    </Box>
  );
};

export default SingleFlight;
