import React, { useState } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../images/Logo.png";
import AdminTransectionAll from "./AdminTransectionAll";
import AdminTransectionDeposit from "./AdminTransectionDeposit";
import AdminTransectionPurchase from "./AdminTransectionPurchase";
import AdminTransectionReturn from "./AdminTransectionReturn";

const AdminTransectionReport = () => {
  const navigate = useNavigate();

  const [optionValue, setOptionValue] = useState("");
  const handleChangeOption = (e) => {
    setOptionValue(e.target.value);
  };
  return (
    <Box style={{ backgroundColor: "var(--secondary-color)", height: "100vh" }}>
      <Container>
        {/*----------- header -------------*/}
        <Box py={4}>
          <Grid
            container
            spaceing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Box
                sx={{
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/")}
              >
                <img src={Logo} alt="Travel Sense" width="180px" />
              </Box>
            </Grid>
            <Grid item>
              <Box>
                <Typography
                  style={{
                    color: "#fff",
                    background: "var(--bgcolor)",
                    padding: "8px 10px",
                    width: "220px",
                  }}
                >
                  Ledger Management
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "20px",
                margin: "10px 0px",
                color: "var(--fontcolor)",
                fontFamily: "poppins",
              }}
            >
              Transection
            </Typography>
          </Box>
          <Box>
            <select
              name="transection"
              id="transectopn"
              style={{
                background: "var(--sweet-color)",
                border: "none",
                color: "#fff",
                width: "130px",
                height: "30px",
                padding: "5px",
              }}
              onChange={handleChangeOption}
            >
              <option value="all">Show All</option>
              <option value="deposit">Deposit</option>
              <option value="purchase">Purchase</option>
              <option value="return">Return</option>
            </select>
          </Box>
        </Box>

        {(optionValue === "all" || optionValue === "") && (
          <AdminTransectionAll />
        )}
        {optionValue === "deposit" && <AdminTransectionDeposit />}
        {optionValue === "purchase" && <AdminTransectionPurchase />}
        {optionValue === "return" && <AdminTransectionReturn />}
      </Container>
    </Box>
  );
};

export default AdminTransectionReport;
