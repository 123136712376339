import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const UpdateProfile = () => {
  const user = secureLocalStorage.getItem("user-info");
  console.log("profile", user);
  const profile = user?.data[0];
  const [fname, setFname] = useState(profile?.name || "");
  return (
    <Box
      sx={{
        mt: 4,
        width: "100%",
      }}
    >
      <Box>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            mb: 3,
          }}
        >
          Personal Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              First Name / Given Name{" "}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <input
                required
                type="text"
                placeholder="First Name / Given Name"
                value={fname?.split(" ")[0]}
                onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Last Name / Surname{" "}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <input
                required
                type="text"
                placeholder="First Name / Given Name"
                value={fname?.split(" ")[1]}
                onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Gender{" "}
            </Typography>
            <Box sx={{ mt: 1 }}>
              <select
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
                required
                onChange={(e) => setFname(e.target.value)}
              >
                <option style={{ color: "black" }} value={"Male"}>
                  Male
                </option>
                <option style={{ color: "black" }} value={"Female"}>
                  Female
                </option>
              </select>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              DOB
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="BOB dd/mm/yyyy"
                value={""}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Profile Image
            </Typography>
            <Box mt={1}>
              <input
                required
                type="file"
                placeholder="Profile Image"
                onChange={(e) => setFname(e.target.value[0])}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "8px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box my={5}>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            my: 3,
          }}
        >
          Contact Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Email
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="Email"
                value={profile?.email}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Phone Number{" "}
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="Email"
                value={profile?.phone}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Address
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="Email"
                value={profile?.address}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box my={5}>
        <Typography
          sx={{
            color: "var(--primary-color)",
            fontSize: 19,
            fontWeight: 500,
            my: 3,
          }}
        >
          Passport Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Number
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="Passport Number"
                value={""}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Expire Date
            </Typography>
            <Box mt={1}>
              <input
                required
                type="text"
                placeholder="Passport Expire date"
                value={""}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "0px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2.4}>
            <Typography
              sx={{
                color: "var(--profile-label)",
                fontSize: 15,
                fontWeight: 500,
              }}
            >
              Passport Copy
            </Typography>
            <Box mt={1}>
              <input
                required
                type="file"
                placeholder="Passport Image"
                value={""}
                // onChange={(e) => setFname(e.target.value)}
                style={{
                  height: "40px",
                  width: "calc(100% - 20px)",
                  border: "none",
                  outline: "none",
                  borderRadius: "0px",
                  padding: "8px 10px",
                  color: "var(--white)",
                  background: "rgba(255, 168, 77, 0.25)",
                  fontSize: "16px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateProfile;
