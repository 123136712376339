import React, { useEffect, useState } from "react";
import {
  Pagination,
  Stack,
  Tooltip,
  CircularProgress,
  Modal,
} from "@mui/material";
import { Container, Grid, IconButton } from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Loader from "../../../images/loader/Render.gif";
import { useQuery } from "react-query";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import commaNumber from "comma-number";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const Payment = () => {
  const users = secureLocalStorage.getItem("user-info");
  let customerId = users?.data[0]?.customerId;

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const [mainPaymentData, setmainPaymentData] = useState([]);
  //  pagination handle
  const [pageCount, setPageCount] = useState(0);

  const [page, setPage] = useState(1);

  let size = 20;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setmainPaymentData(customer?.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch(
      `https://gallpax.flyjatt.com/v1/Deposit/index.php?customerId=${customerId}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        const count = data?.data.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setCustomer(data?.data);
        setmainPaymentData(data?.data);
        setIsLoading(false);
      });
  }, []);

  //  search functionality handle
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = customer.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });
      setmainPaymentData(filterData);
    } else if (searchInput === "") {
      setmainPaymentData(mainPaymentData);
    }
  };

  // modal

  const [id, setId] = useState("");
  const [reason, setReason] = useState("");
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        mt: 4,
        width: "100%",
      }}
    >
      <Box></Box>

      {/* -------------------- */}
      <Box>
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              color: "var(--primary-color)",
            }}
          >
            My Deposit List
          </Typography>

          <Box
            my={2}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <input
                type="text"
                placeholder="search"
                onChange={handelSearchItems}
                style={{
                  width: "280px",
                  border: "none",
                  borderRadius: "2px",
                  height: "34px",
                  color: "#fff",
                  padding: "0px 10px",
                  backgroundColor: "var(--bgcolor)",
                }}
              />
            </Box>
            <Box>
              <NavLink
                to="/adddeposit"
                style={{
                  textDecoration: "none",
                  width: "fit-content",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // background: "var(--primary-color)",
                    color: "#fff",
                    padding: "5px 20px",
                    border: "2px solid var(--primary-color)",
                    borderRadius: "5px",
                  }}
                >
                  Add Deposit
                </Box>
              </NavLink>
            </Box>
          </Box>

          {!isLoading ? (
            <Box className="balance-transaction" marginTop={"20px"}>
              <table>
                <thead>
                  <tr>
                    <th> Deposit&nbsp;Id </th>
                    <th> Sender </th>
                    <th> Receiver </th>
                    <th> &nbsp;Method&nbsp; </th>
                    <th> &nbsp;TransactionId&nbsp; </th>
                    <th>&nbsp;Amount&nbsp;</th>
                    <th> Date </th>
                    <th> Status </th>
                    <th> Vew </th>
                    <th> Remarks </th>
                  </tr>
                </thead>

                {mainPaymentData?.length < 0 ? (
                  ""
                ) : (
                  <tbody>
                    {mainPaymentData?.map((data) => (
                      <tr>
                        <td>{data?.depositId}</td>
                        <td>{data?.sender}</td>
                        <td>{data?.receiver}</td>
                        <td>{data?.paymentmethod}</td>
                        <td>{data?.transactionId}</td>
                        <td>{data?.amount}</td>
                        <td>{data?.created_at}</td>
                        <td>{data?.status}</td>

                        <td>
                          <a
                            id="anchor"
                            href={`https://gallpax.flyjatt.com/assets/deposit/${data?.attachment}`}
                            target="_blank"
                            rel="noreferrer"
                            style={{ color: "#fff" }}
                          >
                            View
                          </a>
                        </td>
                        <td>{data?.remarks === "" ? "N/A" : data?.remarks}</td>
                      </tr>
                    ))}
                  </tbody>
                )}
              </table>
              {mainPaymentData?.length < 0 ? (
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 500,
                    fontSize: 22,
                    textAlign: "center",
                    border: "2px solid #fff",
                    width: "100%",
                  }}
                >
                  No Datad
                </Typography>
              ) : (
                ""
              )}
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                width: "70vw",
                marginInline: "auto",
              }}
            >
              <Box
                style={{
                  width: "50%",
                  height: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Loader}
                  alt="loader"
                  style={{
                    width: "40%",
                    objectFit: "center",
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                width: "100%",
                my: 3,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                borderTop: "1px solid var(--primary-color)",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  my: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",

                  marginTop: "8px",
                }}
              >
                <Typography style={{ fontSize: "15px", color: "#fff" }}>
                  Showing Results {page} - {pageCount}
                </Typography>
                <ThemeProvider theme={theme}>
                  <Stack spacing={2}>
                    <Pagination
                      count={pageCount}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Payment;
