import { Box, Container } from "@mui/system";
import React from "react";
import Slider from "react-slick";

import img1 from "../../images/searchModal/Loading.webp";
import img2 from "../../images/searchModal/tt.jpg";
import img3 from "../../images/searchModal/ttt.jpg";
import img4 from "../../images/searchModal/tttt.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid, Typography } from "@mui/material";

const SearchModal = () => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box sx={{ width: { xs: 300, sm: 500, md: 900 }, position: "relative" }}>
      <Box className="full-screen-slider">
        <Slider {...settings}>
          <div>
            <Box
              sx={{
                width: { xs: 300, sm: 400, md: 900 },
                // height: { xs: 250, sm: 300 },
                position: "relative",
              }}
            >
              <img
                src={img1}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          </div>
        </Slider>
      </Box>
      <Box sx={{ position: "absolute", top: 0, right: 1 }}>
        <SettingsIcon
          sx={{
            fontSize: { xs: 20, sm: 40, md: 70 },
            color: "var(--secondary-color)",
          }}
          className="App-logo"
        />
      </Box>
    </Box>
  );
};

export default SearchModal;
