import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import commaNumber from "comma-number";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./AirlinesNameSlider.css";

const AirlinesNameSlider = ({
  filteredData,
  setfilteredData,
  data,
  setData,
}) => {
  const [carearFlight, setCarearFlight] = useState([]);
  const [flightCheckBox, setFlightCheckBox] = useState(false);

  let carearArray = [];
  data.map((name) => {
    carearArray.push(name.Caree);
    return carearArray;
  });

  let carear = [];

  carearArray.forEach((i) => (carear[i] = false));

  // console.log("carear", carear);

  let totalCarear = Object.keys(carear).map((item) => ({
    name: item,
    // imgname: item,
  }));

  // console.log("totalCarear", totalCarear);

  useEffect(() => {
    setCarearFlight(totalCarear);
  }, []);

  const handleflightCarear = (name) => {
    let updatedflight = data;
    setFlightCheckBox(name.target.checked);
    if (name) {
      updatedflight = updatedflight.filter((item) => item.Caree === name);
      setfilteredData(updatedflight);
    }
  };

  //shorten the whole Array
  let airlineNames = data.map((item) => {
    return {
      careerName: item.Caree,
      isActive: false,
    };
  });

  const [itemsToShow, setItemsToShow] = useState(5);

  const showmore = () => {
    setItemsToShow(totalCarear.length);
  };

  const showless = () => {
    setItemsToShow(5);
  };

  return (
    <div>
      {totalCarear
        .sort((a, b) => a.clientPrice - b.clientPrice)
        .slice(0, itemsToShow)
        .map((item, index) => {
          return (
            <Box width="100%" key={index}>
              <Tooltip title={item.name}>
                <FormGroup>
                  <FormControlLabel
                    value={item.name}
                    control={<Checkbox sx={{ color: "var(--secondary-color)"}} />}
                    checked={flightCheckBox}
                    onChange={() => handleflightCarear(item.name)}
                    label={
                      <Typography sx={{ color: "var(--secondary-color)", fontSize: 14 }}>
                        {item.name}
                      </Typography>
                    }
                  />
                </FormGroup>
              </Tooltip>
            </Box>
          );
        })}
      {totalCarear.length > 5 ? (
        <>
          {itemsToShow === 5 ? (
            <button
              style={{
                outline: "none",
                border: "none",
                cursor: "pointer",
                padding: "2px 10px",
              }}
              onClick={showmore}
            >
              Show More
            </button>
          ) : (
            <button
              style={{
                outline: "none",
                border: "none",
                cursor: "pointer",
                padding: "2px 10px",
              }}
              onClick={showless}
            >
              Show Less
            </button>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default AirlinesNameSlider;
